import React, { useEffect, useState } from 'react';
import './workoutCollapse.scss';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import ExerciseTable from './ExerciseTable';
import ExerciseTableGroup from './ExerciseTableGroup';
import Button from '@material-ui/core/Button';
import TimeBar from '../../Nutrition/NutritionPlans/TimeBar';
import DaysWeekBar from '../../../DaysWeekBar/DaysWeekBar';
import { addMovementWorkoutGoal, updateMovementWorkout } from '../../../../../redux/actions/PlansAction';
import { useDispatch } from 'react-redux';

const WorkoutCollapse = ({ workout }) => {
  const dispatch = useDispatch();
  const [workoutData, setWorkoutData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [selectedWorkoutData, setSelectedWorkoutData] = useState([]);
  const [actionTimebar, setActionTimebar] = useState(false);
  const [workoutListData, setWorkoutListData] = useState(workout);
  const [timeBar, setTimeBar] = useState({
    days: [],
    week: [],
    month: [],
  });

useEffect(() => {
  setWorkoutListData(workout)
}, [workout])

  const handleAddGroup = () => {
    setCounter(prevState => prevState + 2);
    const defaultWorkoutData = {
      groupId: counter,
      workout: null,
      groupWorkout: {
        groupData: [
          { id: counter + 1, title: `Exercise name ${counter + 1}` },
          { id: counter + 2, title: `Exercise name ${counter + 2}` },
        ],
      },
    };
    const newData = [...workoutData, defaultWorkoutData];
    setWorkoutData(newData);
  };
  const handleUpdateName = (id, title, groupId) => {
    const updateDataIndex = workoutData.findIndex(i => i.groupId === groupId);
    const updateInnerDataIndex = workoutData[updateDataIndex].workout.data.findIndex(i => i.id === id);
    const updateData = [...workoutData];
    updateData[updateDataIndex].workout.data[updateInnerDataIndex].title = title;
    setWorkoutData(updateData);
  };

  /* Add select checkbox group function */
  const handleSelectedGroup = () => {
    setCounter(prevState => prevState + 1);
    const defaultWorkoutData = {
      groupId: counter,
      workout: null,
      groupWorkout: {
        groupData: selectedWorkoutData,
      },
    };
    const filterWorkoutData = workoutData.filter(data => {
      const selectedWorkoutDataID = selectedWorkoutData.map(i => i.id);
      if (selectedWorkoutDataID.includes(data.workout?.data[0].id)) return;
      return data;
    });

    const newData = [...filterWorkoutData, defaultWorkoutData];
    setWorkoutData(newData);
    setSelectedWorkoutData([]);
  };

  /* checkbox handle */
  const handleChecked = (selectId, groupId) => () => {
    const groupIdIndex = workoutData.findIndex(g => g.groupId === groupId);
    const allData = workoutData;
    allData[groupIdIndex].workout.data[0].isChecked = !allData[groupIdIndex].workout.data[0].isChecked;
    setWorkoutData([...allData]);
    if (allData[groupIdIndex].workout.data[0].isChecked) {
      const selectRecord = allData[groupIdIndex].workout.data[0];
      setSelectedWorkoutData(prevState => [...prevState, selectRecord]);
    } else {
      setSelectedWorkoutData(selectedWorkoutData.filter(i => i.id !== selectId));
    }
  };
  /* Add Exercise function */
  const handleAddExercise = () => {
    setCounter(prevState => prevState + 1);
    const defaultWorkoutData = {
      groupId: counter,
      workout: { data: [{ id: counter + 1, title: `Exercise name ${counter + 1}`, isChecked: false }] },
      groupWorkout: null,
    };
    const newData = [...workoutData, defaultWorkoutData];
    setWorkoutData(newData);

    // dispatch(
    //   addMovementWorkoutGoal({
    //     goals: [
    //       {
    //         goal_type: 'reps',
    //         target: 0,
    //         unit: 'as_many',
    //         time: 0,
    //       },
    //     ],
    //     exercise: 1,
    //     rounds: 1,
    //     line: 1,
    //   }),
    // );
  };
  const WorkoutScreen = () => {
    return workoutData.map((data, index) => {
      const { workout, groupWorkout, groupId } = data;
      if (workout) {
        return (
          <ExerciseTable
            key={index}
            data={workout.data[0]}
            handleChecked={handleChecked}
            groupId={groupId}
            handleUpdateName={handleUpdateName}
          />
        );
      }
      if (groupWorkout) {
        return <ExerciseTableGroup key={index} groupData={groupWorkout.groupData} />;
      }
    });
  };
  const handleTime = () => {
    setActionTimebar(true);
  };

  const onChangeHandler = ({ target: { name, value } }) => {
    setWorkoutListData({ ...workoutListData, [name]: value });
  };
  const handleUpdateWorkout = type => () => {
    const dataKey = Object.keys(type)[0];
    if (workout[dataKey] !== type[dataKey]) dispatch(updateMovementWorkout({ id: workout.id, payload: type }));
  };
  return (
    <>
      <div className="workoutCollapse">
        <div className="workoutCollapseHeader">
          <Row className="headerInput">
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Workout name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Workout name"
                  name="name"
                  onBlur={handleUpdateWorkout({ name: workoutListData?.name })}
                  onChange={onChangeHandler}
                  value={workoutListData?.name}
                />
              </Form.Group>
            </Col>
            <Col xs={8}>
              <Form.Group>
                <Form.Label>Workout description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  placeholder="Workout description"
                  onBlur={handleUpdateWorkout({ description: workoutListData?.description })}
                  onChange={onChangeHandler}
                  value={workoutListData?.description}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="workoutCollapseTable">
          {/*inner workout level */}
          {actionTimebar && (
            <DaysWeekBar days={timeBar.days} week={timeBar.week} month={timeBar.month} setTimeBar={setTimeBar} />
          )}
          <table className="w-100">
            <tr className="workoutTableTitle">
              <td>exercise</td>
              <td>Rounds</td>
              <td>goal type</td>
              <td>Target</td>
              <td>timed</td>
            </tr>

            <WorkoutScreen />
          </table>

          <div className="px-3 py-2">
            {!actionTimebar ? (
              <Button className="whiteBtn" onClick={handleTime}>
                + Add time selection
              </Button>
            ) : (
              <>
                <Button className="whiteBtn me-3" onClick={handleAddGroup}>
                  + Add group
                </Button>
                <Button
                  className="whiteBtn me-3"
                  onClick={handleSelectedGroup}
                  disabled={selectedWorkoutData.length < 2}
                >
                  Group selected
                </Button>
                <Button className="whiteBtn me-3" onClick={handleAddExercise}>
                  + Add EXERCISE
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="workoutCollapseFooter" />
      </div>
    </>
  );
};
export default WorkoutCollapse;
