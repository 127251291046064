import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Axios } from '../../api/axios';
import { getSimplifiedError } from '../../utils/error';
import { toast } from 'react-toastify';

import {
  RESET_FLAGS_PLANS,
  CREATE_USER_CORELATION_REQUEST,
  CREATE_USER_CORELATION_SUCCESS,
  CREATE_USER_CORELATION_ERROR,
  GET_USER_TYPE_REQUEST,
  GET_USER_TYPE_SUCCESS,
  GET_USER_TYPE_ERROR,
  GET_MOVEMENT_WORKOUT_REQUEST,
  GET_DETAIL_MOVEMENT_WORKOUT_REQUEST,
  POST_MOVEMENT_WORKOUT_REQUEST,
  GET_DETAIL_MOVEMENT_WORKOUT_SUCCESS,
  GET_DETAIL_MOVEMENT_WORKOUT_ERROR,
  DELETE_MOVEMENT_WORKOUT_REQUEST,
  DELETE_MOVEMENT_WORKOUT_SUCCESS,
  DELETE_MOVEMENT_WORKOUT_ERROR,
  POST_MOVEMENT_WORKOUT_ERROR,
  POST_MOVEMENT_WORKOUT_SUCCESS,
  GET_MOVEMENT_WORKOUT_SUCCESS,
  GET_MOVEMENT_WORKOUT_ERROR,
  GET_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
  GET_MOVEMENT_WORKOUT_LEVEL_ERROR,
  GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
  GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_ERROR,
  POST_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
  POST_MOVEMENT_WORKOUT_LEVEL_ERROR,
  GET_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  POST_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  DELETE_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  DELETE_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
  DELETE_MOVEMENT_WORKOUT_LEVEL_ERROR,
  GET_USER_CORELATION_REQUEST,
  GET_USER_CORELATION_SUCCESS,
  GET_USER_CORELATION_ERROR,
  ADD_NEW_USER_CORELATION_REQUEST,
  ADD_NEW_USER_CORELATION_SUCCESS,
  ADD_NEW_USER_CORELATION_ERROR,
  DELETE_USER_CORELATION_REQUEST,
  DELETE_USER_CORELATION_SUCCESS,
  DELETE_USER_CORELATION_ERROR,
  UPDATE_MOVEMENT_WORKOUT_LEVEL_REQUEST,
  UPDATE_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
  UPDATE_MOVEMENT_WORKOUT_LEVEL_ERROR,
  UPDATE_MOVEMENT_WORKOUT_REQUEST,
  UPDATE_MOVEMENT_WORKOUT_SUCCESS,
  UPDATE_MOVEMENT_WORKOUT_ERROR,
  GET_MOVEMENT_WORKOUT_GOAL_REQUEST,
  UPDATE_MOVEMENT_WORKOUT_GOAL_REQUEST,
  POST_MOVEMENT_WORKOUT_GOAL_REQUEST,
  DELETE_MOVEMENT_WORKOUT_GOAL_REQUEST,
  GET_MOVEMENT_WORKOUT_GOAL_SUCCESS,
  GET_MOVEMENT_WORKOUT_GOAL_ERROR,
  UPDATE_MOVEMENT_WORKOUT_GOAL_SUCCESS,
  UPDATE_MOVEMENT_WORKOUT_GOAL_ERROR,
  POST_MOVEMENT_WORKOUT_GOAL_SUCCESS,
  POST_MOVEMENT_WORKOUT_GOAL_ERROR,
  DELETE_MOVEMENT_WORKOUT_GOAL_SUCCESS,
  DELETE_MOVEMENT_WORKOUT_GOAL_ERROR,
  GET_NUTRITION_LEVEL_REQUEST,
  GET_DETAIL_NUTRITION_LEVEL_REQUEST,
  POST_NUTRITION_LEVEL_REQUEST,
  DELETE_NUTRITION_LEVEL_REQUEST,
  GET_NUTRITION_LEVEL_SUCCESS,
  GET_NUTRITION_LEVEL_ERROR,
  GET_DETAIL_NUTRITION_LEVEL_SUCCESS,
  GET_DETAIL_NUTRITION_LEVEL_ERROR,
  POST_NUTRITION_LEVEL_SUCCESS,
  POST_NUTRITION_LEVEL_ERROR,
  DELETE_NUTRITION_LEVEL_SUCCESS,
  DELETE_NUTRITION_LEVEL_ERROR,
  GET_NUTRITION_PLAN_SUCCESS,
  GET_NUTRITION_PLAN_ERROR,
  GET_DETAIL_NUTRITION_PLAN_SUCCESS,
  GET_DETAIL_NUTRITION_PLAN_ERROR,
  POST_NUTRITION_PLAN_SUCCESS,
  POST_NUTRITION_PLAN_ERROR,
  DELETE_NUTRITION_PLAN_SUCCESS,
  DELETE_NUTRITION_PLAN_ERROR,
  GET_NUTRITION_PLAN_REQUEST,
  GET_DETAIL_NUTRITION_PLAN_REQUEST,
  POST_NUTRITION_PLAN_REQUEST,
  DELETE_NUTRITION_PLAN_REQUEST,
  GET_NUTRITION_HABIT_SUCCESS,
  GET_NUTRITION_HABIT_ERROR,
  GET_DETAIL_NUTRITION_HABIT_SUCCESS,
  GET_DETAIL_NUTRITION_HABIT_ERROR,
  POST_NUTRITION_HABIT_SUCCESS,
  POST_NUTRITION_HABIT_ERROR,
  DELETE_NUTRITION_HABIT_SUCCESS,
  DELETE_NUTRITION_HABIT_ERROR,
  GET_NUTRITION_HABIT_REQUEST,
  GET_DETAIL_NUTRITION_HABIT_REQUEST,
  POST_NUTRITION_HABIT_REQUEST,
  DELETE_NUTRITION_HABIT_REQUEST,
  UPDATE_NUTRITION_HABIT_SUCCESS,
  UPDATE_NUTRITION_HABIT_ERROR,
  UPDATE_NUTRITION_HABIT_REQUEST,
  GET_MORNING_MOMENT_SUCCESS,
  GET_MORNING_MOMENT_ERROR,
  UPDATE_MORNING_MOMENT_SUCCESS,
  UPDATE_MORNING_MOMENT_ERROR,
  GET_DETAIL_MORNING_MOMENT_SUCCESS,
  GET_DETAIL_MORNING_MOMENT_ERROR,
  POST_MORNING_MOMENT_SUCCESS,
  POST_MORNING_MOMENT_ERROR,
  DELETE_MORNING_MOMENT_SUCCESS,
  DELETE_MORNING_MOMENT_ERROR,
  GET_MORNING_MOMENT_REQUEST,
  UPDATE_MORNING_MOMENT_REQUEST,
  GET_DETAIL_MORNING_MOMENT_REQUEST,
  POST_MORNING_MOMENT_REQUEST,
  DELETE_MORNING_MOMENT_REQUEST,
  ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST,
  GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST,
  ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS,
  ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR,
  GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS,
  GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR,
} from '../reducers/PlansReducer';

async function getUserType() {
  return await Axios.get('/user_type/');
}
function* handleGetUserType() {
  try {
    const response = yield call(getUserType);
    if (response) {
      yield put({
        type: GET_USER_TYPE_SUCCESS,
        data: response.results,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'getUser' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_TYPE_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function getUserCorelation() {
  return await Axios.get('/corelation/');
}
function* handleGetUserCorelation() {
  try {
    const response = yield call(getUserCorelation);
    if (response) {
      yield put({
        type: GET_USER_CORELATION_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'getUserCorelation' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_CORELATION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function addNewUserCorelation({ userTypeId, data }) {
  return await Axios.post(`/corelation/${userTypeId}/add_response/`, data);
}
function* handleAddNewUserCorelation({ payload }) {
  try {
    const response = yield call(addNewUserCorelation, payload);
    if (response) {
      yield put({
        type: ADD_NEW_USER_CORELATION_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'updateUserCorelation' },
      });
    }
  } catch (error) {
    yield put({
      type: ADD_NEW_USER_CORELATION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function deleteUserCorelation({ id, data }) {
  return await Axios.delete(`/corelation/${id}/`);
}
function* handleDeleteUserCorelation({ payload }) {
  try {
    const response = yield call(deleteUserCorelation, payload);
    if (response) {
      yield put({
        type: DELETE_USER_CORELATION_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'deleteUserCorelation' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_USER_CORELATION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function createUserCorelation(payload) {
  return await Axios.post('/corelation/', payload);
}
function* handleCreateUserCorelation({ payload }) {
  try {
    const response = yield call(createUserCorelation, payload);
    if (response) {
      yield put({
        type: CREATE_USER_CORELATION_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'createUserCorelation' },
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_USER_CORELATION_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* ******************************  Movement ***************************** */

/*---------- Movement Workout ----------*/
/* All Movement Workout Data */
async function getMovementWorkout() {
  return await Axios.get('/movement/workout/');
}
function* handleGetMovementWorkout() {
  try {
    const response = yield call(getMovementWorkout);
    if (response) {
      yield put({
        type: GET_MOVEMENT_WORKOUT_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'movementWorkout' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_MOVEMENT_WORKOUT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function updateMovementWorkout({ id, payload }) {
  return await Axios.patch(`/movement/workout/${id}/`, payload);
}
function* handleUpdateMovementWorkout({ payload }) {
  try {
    const response = yield call(updateMovementWorkout, payload);
    if (response) {
      yield put({
        type: UPDATE_MOVEMENT_WORKOUT_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'movementWorkout' },
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_MOVEMENT_WORKOUT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Movement Workout Data */
async function getMovementWorkoutDetail(id) {
  return await Axios.get(`/movement/workout/${id}/`);
}
function* handleGetMovementWorkoutDetail(payload) {
  try {
    const response = yield call(getMovementWorkoutDetail, payload);
    if (response) {
      yield put({
        type: GET_DETAIL_MOVEMENT_WORKOUT_SUCCESS,
        data: response.results,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'selectedMovementWorkout' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_DETAIL_MOVEMENT_WORKOUT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Movement Workout Data */
async function addMovementWorkout(payload) {
  return await Axios.post(`/movement/workout/`, payload);
}
function* handleAddMovementWorkout({ payload }) {
  try {
    const response = yield call(addMovementWorkout, payload);
    if (response) {
      yield put({
        type: POST_MOVEMENT_WORKOUT_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'addMovementWorkout' },
      });
    }
  } catch (error) {
    yield put({
      type: POST_MOVEMENT_WORKOUT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Delete Movement Workout Data */
async function deleteMovementWorkout(payload) {
  return await Axios.delete(`movement/workout/${payload}/`);
}
function* handleDeleteMovementWorkout({ payload }) {
  try {
    const response = yield call(deleteMovementWorkout, payload);
    if (response === '') {
      yield put({
        type: DELETE_MOVEMENT_WORKOUT_SUCCESS,
        id: payload,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'deleteMovementWorkout' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_MOVEMENT_WORKOUT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/*---------- Movement Workout End----------*/

/*---------- Movement Workout Level ----------*/
/* All Movement Workout Level Data */
async function getMovementWorkoutLevel() {
  return await Axios.get('/movement/level/');
}
function* handleGetMovementWorkoutLevel() {
  try {
    const response = yield call(getMovementWorkoutLevel);
    if (response) {
      yield put({
        type: GET_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'movementWorkoutLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_MOVEMENT_WORKOUT_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function updateMovementWorkoutLevel({ id, payload }) {
  return await Axios.patch(`/movement/level/${id}/`, payload);
}
function* handleUpdateMovementWorkoutLevel({ payload }) {
  try {
    const response = yield call(updateMovementWorkoutLevel, payload);
    if (response) {
      yield put({
        type: UPDATE_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'movementWorkoutLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_MOVEMENT_WORKOUT_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Movement Workout Level Data */
async function getMovementWorkoutLevelDetail(id) {
  return await Axios.get(`/movement/level/${id}/`);
}
function* handleGetMovementWorkoutLevelDetail(payload) {
  try {
    const response = yield call(getMovementWorkoutLevelDetail, payload);
    if (response) {
      yield put({
        type: GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
        data: response.results,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'selectedMovementWorkoutLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Movement Workout Level Data */
async function addWorkoutMovementLevel(payload) {
  return await Axios.post(`/movement/level/`, payload);
}
function* handleAddMovementWorkoutLevel({ payload }) {
  try {
    const response = yield call(addWorkoutMovementLevel, payload);
    if (response) {
      yield put({
        type: POST_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'addMovementWorkoutLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: POST_MOVEMENT_WORKOUT_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Delete Movement Workout Level Data */
async function deleteMovementWorkoutLevel(payload) {
  return await Axios.delete(`movement/level/${payload}/`);
}
function* handleDeleteMovementWorkoutLevel({ payload }) {
  try {
    const response = yield call(deleteMovementWorkoutLevel, payload);
    if (response === '') {
      yield put({
        type: DELETE_MOVEMENT_WORKOUT_LEVEL_SUCCESS,
        id: payload,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'deleteMovementWorkoutLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_MOVEMENT_WORKOUT_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}
/*---------- Movement Workout Level End ----------*/

/*---------- Movement Workout Goal----------*/
/* All Movement Workout Data */
async function getMovementWorkoutGoal() {
  return await Axios.get('/movement/goal/');
}
function* handleGetMovementWorkoutGoal() {
  try {
    const response = yield call(getMovementWorkoutGoal);
    if (response) {
      yield put({
        type: GET_MOVEMENT_WORKOUT_GOAL_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'movementWorkoutGoal' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_MOVEMENT_WORKOUT_GOAL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function updateMovementWorkoutGoal({ id, payload }) {
  return await Axios.patch(`/movement/goal/${id}/`, payload);
}
function* handleUpdateMovementWorkoutGoal({ payload }) {
  try {
    const response = yield call(updateMovementWorkoutGoal, payload);
    if (response) {
      yield put({
        type: UPDATE_MOVEMENT_WORKOUT_GOAL_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'movementWorkoutGoal' },
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_MOVEMENT_WORKOUT_GOAL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Movement Workout Data */
async function addMovementWorkoutGoal(payload) {
  return await Axios.post(`/movement/goal/`, payload);
}
function* handleAddMovementWorkoutGoal({ payload }) {
  try {
    const response = yield call(addMovementWorkoutGoal, payload);
    if (response) {
      yield put({
        type: POST_MOVEMENT_WORKOUT_GOAL_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'addMovementWorkoutGoal' },
      });
    }
  } catch (error) {
    yield put({
      type: POST_MOVEMENT_WORKOUT_GOAL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Delete Movement Workout Data */
async function deleteMovementWorkoutGoal(payload) {
  return await Axios.delete(`movement/goal/${payload}/`);
}
function* handleDeleteMovementWorkoutGoal({ payload }) {
  try {
    const response = yield call(deleteMovementWorkoutGoal, payload);
    if (response === '') {
      yield put({
        type: DELETE_MOVEMENT_WORKOUT_GOAL_SUCCESS,
        id: payload,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'deleteMovementWorkoutGoal' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_MOVEMENT_WORKOUT_GOAL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/*---------- Movement Workout Goal End----------*/

/*---------- Movement Exercise library Category Right side----------*/
/* library Category Add */
async function addExerciseLibraryCategory(payload) {
  return await Axios.post(`/movement/category/`, payload);
}
function* handleAddMovementExerciseLibraryCategory({ payload }) {
  try {
    const response = yield call(addExerciseLibraryCategory, payload);
    if (response) {
      yield put({
        type: ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'addMovementExerciseLibraryCategory' },
      });
    }
  } catch (error) {
    yield put({
      type: ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* library Category Get */
async function getExerciseLibraryCategory() {
  return await Axios.get(`/movement/category/`);
}
function* handleGetMovementExerciseLibraryCategory() {
  try {
    const response = yield call(getExerciseLibraryCategory);
    if (response) {
      yield put({
        type: GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'movementExerciseLibraryCategory' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}
/*---------- Movement Exercise library Category Right side----------*/

/* ******************************  Movement End ***************************** */

/*
 *
 *
 * ******************************  NUTRITION ***************************** */
/*---------- Nutrition Level ----------*/
/* All Nutrition Level Data */
async function getNutritionLevel() {
  return await Axios.get('/nutrition-level/');
}
function* handleGetNutritionLevel() {
  try {
    const response = yield call(getNutritionLevel);
    if (response) {
      yield put({
        type: GET_NUTRITION_LEVEL_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'nutritionLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_NUTRITION_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Nutrition Level Data */
async function getNutritionLevelDetail(id) {
  return await Axios.get(`/nutrition-level/${id}/`);
}
function* handleGetNutritionLevelDetail(payload) {
  try {
    const response = yield call(getNutritionLevelDetail, payload);
    if (response) {
      yield put({
        type: GET_DETAIL_NUTRITION_LEVEL_SUCCESS,
        data: response.results,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'selectedNutritionLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_DETAIL_NUTRITION_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Nutrition Level Data */
async function addNutritionLevel(payload) {
  return await Axios.post(`/nutrition-level/`, payload);
}
function* handleAddNutritionLevel({ payload }) {
  try {
    const response = yield call(addNutritionLevel, payload);
    if (response) {
      yield put({
        type: POST_NUTRITION_LEVEL_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'addNutritionLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: POST_NUTRITION_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Delete Nutrition Level Data */
async function deleteNutritionLevel(payload) {
  return await Axios.delete(`nutrition-level/${payload}/`);
}
function* handleDeleteNutritionLevel({ payload }) {
  try {
    const response = yield call(deleteNutritionLevel, payload);
    if (response === '') {
      yield put({
        type: DELETE_NUTRITION_LEVEL_SUCCESS,
        id: payload,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'deleteNutritionLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_NUTRITION_LEVEL_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}
/*---------- Nutrition Level End ----------*/

/*---------- Nutrition Plan start ----------*/
async function getNutritionPlan() {
  return await Axios.get('/nutrition-plan/');
}
function* handleGetNutritionPlan() {
  try {
    const response = yield call(getNutritionPlan);
    if (response) {
      yield put({
        type: GET_NUTRITION_PLAN_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'nutritionLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_NUTRITION_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Nutrition Level Data */
async function getNutritionPlanDetail(id) {
  return await Axios.get(`/nutrition-plan/${id}/`);
}
function* handleGetNutritionPlanDetail(payload) {
  try {
    const response = yield call(getNutritionPlanDetail, payload);
    if (response) {
      yield put({
        type: GET_DETAIL_NUTRITION_PLAN_SUCCESS,
        data: response.results,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'selectedNutritionLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_DETAIL_NUTRITION_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Nutrition Level Data */
async function addNutritionPlan(payload) {
  return await Axios.post(`/nutrition-plan/`, payload);
}
function* handleAddNutritionPlan({ payload }) {
  try {
    const response = yield call(addNutritionPlan, payload);
    if (response) {
      yield put({
        type: POST_NUTRITION_PLAN_SUCCESS,
        data: response,
      });
      const { id, level } = response;
      yield put({
        type: POST_NUTRITION_HABIT_REQUEST,
        payload: {
          plan: id,
          order: level,
          text: 'this is text',
          video: `https://www.youtube.com/watch?v=D0UnqGm_miA`,
          notes: 'this is note',
          plan_type: 'number',
          target: '1',
        },
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'addNutritionLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: POST_NUTRITION_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Delete Nutrition Level Data */
async function deleteNutritionPlan({ id }) {
  return await Axios.delete(`nutrition-plan/${id}/`);
}
function* handleDeleteNutritionPlan({ payload }) {
  try {
    const response = yield call(deleteNutritionPlan, payload);
    if (response === '') {
      yield put({
        type: DELETE_NUTRITION_PLAN_SUCCESS,
        id: payload.id,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'deleteNutritionLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_NUTRITION_PLAN_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/*---------- Nutrition Plan End ----------*/

/*---------- Nutrition Habit start ----------*/
async function getNutritionHabit() {
  return await Axios.get('/nutrition/habit/');
}
function* handleGetNutritionHabit() {
  try {
    const response = yield call(getNutritionHabit);
    if (response) {
      yield put({
        type: GET_NUTRITION_HABIT_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'nutritionLevel' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_NUTRITION_HABIT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function updateNutritionHabit({ id, payload }) {
  return await Axios.patch(`/nutrition/habit/${id}/`, payload);
}
function* handleUpdateNutritionHabit({ payload }) {
  try {
    const response = yield call(updateNutritionHabit, payload);
    if (response) {
      yield put({
        type: UPDATE_NUTRITION_HABIT_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'nutritionHabit' },
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_NUTRITION_HABIT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Nutrition Habit Data */
async function getNutritionHabitDetail(id) {
  return await Axios.get(`/nutrition/habit/${id}/`);
}
function* handleGetNutritionHabitDetail(payload) {
  try {
    const response = yield call(getNutritionHabitDetail, payload);
    if (response) {
      yield put({
        type: GET_DETAIL_NUTRITION_HABIT_SUCCESS,
        data: response.results,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'selectedNutritionHabit' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_DETAIL_NUTRITION_HABIT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Nutrition Habit Data */
async function addNutritionHabit(payload) {
  return await Axios.post(`/nutrition/habit/`, payload);
}
function* handleAddNutritionHabit({ payload }) {
  try {
    const response = yield call(addNutritionHabit, payload);
    if (response) {
      yield put({
        type: POST_NUTRITION_HABIT_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'addNutritionHabit' },
      });
    }
  } catch (error) {
    yield put({
      type: POST_NUTRITION_HABIT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Delete Nutrition Habit Data */
async function deleteNutritionHabit({ id }) {
  return await Axios.delete(`/nutrition/habit/${id}/`);
}
function* handleDeleteNutritionHabit({ payload }) {
  try {
    const response = yield call(deleteNutritionHabit, payload);
    if (response === '') {
      yield put({
        type: DELETE_NUTRITION_HABIT_SUCCESS,
        id: payload,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'deleteNutritionHabit' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_NUTRITION_HABIT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}
/*---------- Nutrition Habit end ----------*/
/* ******************************  NUTRITION END ***************************** */

/* ******************************  MORNING MOMENT START ***************************** */
async function getMoment() {
  return await Axios.get('/morning_moment/moment/');
}
function* handleGetMoment() {
  try {
    const response = yield call(getMoment);
    if (response) {
      yield put({
        type: GET_MORNING_MOMENT_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'morningMoment' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_MORNING_MOMENT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

async function updateMoment({ id, payload }) {
  return await Axios.patch(`/morning_moment/moment/${id}/`, payload);
}
function* handleUpdateMoment({ payload }) {
  try {
    const response = yield call(updateMoment, payload);
    if (response) {
      yield put({
        type: UPDATE_MORNING_MOMENT_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'morningMoment' },
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_MORNING_MOMENT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Nutrition Level Data */
async function getMomentDetail(id) {
  return await Axios.get(`/morning_moment/moment/${id}/`);
}
function* handleGetMomentDetail(payload) {
  try {
    const response = yield call(getMomentDetail, payload);
    if (response) {
      yield put({
        type: GET_DETAIL_MORNING_MOMENT_SUCCESS,
        data: response.results,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'selectedMorningMoment' },
      });
    }
  } catch (error) {
    yield put({
      type: GET_DETAIL_MORNING_MOMENT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Single Nutrition Level Data */
async function addMoment(payload) {
  return await Axios.post(`/morning_moment/moment/`, payload);
}
function* handleAddMoment({ payload }) {
  try {
    const response = yield call(addMoment, payload);
    if (response) {
      yield put({
        type: POST_MORNING_MOMENT_SUCCESS,
        data: response,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'addMorningMoment' },
      });
    }
  } catch (error) {
    yield put({
      type: POST_MORNING_MOMENT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

/* Delete Nutrition Level Data */
async function deleteMoment({ id }) {
  return await Axios.delete(`/morning_moment/moment/${id}/`);
}
function* handleDeleteMoment({ payload }) {
  try {
    const response = yield call(deleteMoment, payload);
    if (response === '') {
      yield put({
        type: DELETE_MORNING_MOMENT_SUCCESS,
        id: payload.id,
      });
      yield put({
        type: RESET_FLAGS_PLANS,
        payload: { blockType: 'deleteMorningMoment' },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_MORNING_MOMENT_ERROR,
      error: getSimplifiedError(error),
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}
/* ******************************  MORNING MOMENT END ***************************** */

export default all([
  takeLatest(GET_USER_TYPE_REQUEST, handleGetUserType),
  takeLatest(GET_USER_CORELATION_REQUEST, handleGetUserCorelation),
  takeLatest(CREATE_USER_CORELATION_REQUEST, handleCreateUserCorelation),
  takeLatest(ADD_NEW_USER_CORELATION_REQUEST, handleAddNewUserCorelation),
  takeLatest(DELETE_USER_CORELATION_REQUEST, handleDeleteUserCorelation),
  /************************ Movement ***************/
  /* MOVEMENT_WORKOUT */
  takeLatest(GET_MOVEMENT_WORKOUT_REQUEST, handleGetMovementWorkout),
  takeLatest(UPDATE_MOVEMENT_WORKOUT_REQUEST, handleUpdateMovementWorkout),
  takeLatest(GET_DETAIL_MOVEMENT_WORKOUT_REQUEST, handleGetMovementWorkoutDetail),
  takeLatest(POST_MOVEMENT_WORKOUT_REQUEST, handleAddMovementWorkout),
  takeLatest(DELETE_MOVEMENT_WORKOUT_REQUEST, handleDeleteMovementWorkout),
  /* END MOVEMENT_WORKOUT */

  /* MOVEMENT_WORKOUT  GOAL*/
  takeLatest(GET_MOVEMENT_WORKOUT_GOAL_REQUEST, handleGetMovementWorkoutGoal),
  takeLatest(UPDATE_MOVEMENT_WORKOUT_GOAL_REQUEST, handleUpdateMovementWorkoutGoal),
  takeLatest(POST_MOVEMENT_WORKOUT_GOAL_REQUEST, handleAddMovementWorkoutGoal),
  takeLatest(DELETE_MOVEMENT_WORKOUT_GOAL_REQUEST, handleDeleteMovementWorkoutGoal),
  /* END MOVEMENT_WORKOUT */

  /* MOVEMENT_WORKOUT_LEVEL */
  takeLatest(GET_MOVEMENT_WORKOUT_LEVEL_REQUEST, handleGetMovementWorkoutLevel),
  takeLatest(UPDATE_MOVEMENT_WORKOUT_LEVEL_REQUEST, handleUpdateMovementWorkoutLevel),
  takeLatest(GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_REQUEST, handleGetMovementWorkoutLevelDetail),
  takeLatest(POST_MOVEMENT_WORKOUT_LEVEL_REQUEST, handleAddMovementWorkoutLevel),
  takeLatest(DELETE_MOVEMENT_WORKOUT_LEVEL_REQUEST, handleDeleteMovementWorkoutLevel),

  /* MOVEMENT EXERCISE LIBRARY  CATEGORY */
  takeLatest(ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST, handleAddMovementExerciseLibraryCategory),
  takeLatest(GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST, handleGetMovementExerciseLibraryCategory),
  /* MOVEMENT EXERCISE LIBRARY  CATEGORY */

  /************************ Movement End ***************/

  /*
   *
   * *********************** Nutrition ***************/
  /* MOVEMENT_WORKOUT_LEVEL */
  takeLatest(GET_NUTRITION_LEVEL_REQUEST, handleGetNutritionLevel),
  takeLatest(GET_DETAIL_NUTRITION_LEVEL_REQUEST, handleGetNutritionLevelDetail),
  takeLatest(POST_NUTRITION_LEVEL_REQUEST, handleAddNutritionLevel),
  takeLatest(DELETE_NUTRITION_LEVEL_REQUEST, handleDeleteNutritionLevel),
  /* END MOVEMENT_WORKOUT_LEVEL */

  /* MOVEMENT_WORKOUT_PLAN */
  takeLatest(GET_NUTRITION_PLAN_REQUEST, handleGetNutritionPlan),
  takeLatest(GET_DETAIL_NUTRITION_PLAN_REQUEST, handleGetNutritionPlanDetail),
  takeLatest(POST_NUTRITION_PLAN_REQUEST, handleAddNutritionPlan),
  takeLatest(DELETE_NUTRITION_PLAN_REQUEST, handleDeleteNutritionPlan),
  /* END MOVEMENT_WORKOUT_PLAN */

  /* MOVEMENT_WORKOUT_HABIT */
  takeLatest(GET_NUTRITION_HABIT_REQUEST, handleGetNutritionHabit),
  takeLatest(UPDATE_NUTRITION_HABIT_REQUEST, handleUpdateNutritionHabit),
  takeLatest(GET_DETAIL_NUTRITION_HABIT_REQUEST, handleGetNutritionHabitDetail),
  takeLatest(POST_NUTRITION_HABIT_REQUEST, handleAddNutritionHabit),
  takeLatest(DELETE_NUTRITION_HABIT_REQUEST, handleDeleteNutritionHabit),
  /* END MOVEMENT_WORKOUT_HABIT */

  /*********************** Nutrition End ***************/

  /*********************** Morning Moment Start ***************/
  takeLatest(GET_MORNING_MOMENT_REQUEST, handleGetMoment),
  takeLatest(UPDATE_MORNING_MOMENT_REQUEST, handleUpdateMoment),
  takeLatest(GET_DETAIL_MORNING_MOMENT_REQUEST, handleGetMomentDetail),
  takeLatest(POST_MORNING_MOMENT_REQUEST, handleAddMoment),
  takeLatest(DELETE_MORNING_MOMENT_REQUEST, handleDeleteMoment),
  /*********************** Morning MomentEnd ***************/
]);
