import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import MoveIcon from '../../../../../assets/icons/MoveIcon';
import Card from 'react-bootstrap/Card';
import CustomToggleExercises from '../../../../Exercises/ExercisesAccordionUi/CustomToggleExercises';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Accordion } from 'react-bootstrap';
import WorkoutCollapse from './WorkoutCollapse';
import './workoutLevel.scss';
import DaysWeekBar from '../../../DaysWeekBar/DaysWeekBar';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import {
  addMovementWorkout,
  deleteMovementWorkout,
  updateMovementWorkoutLevel,
} from '../../../../../redux/actions/PlansAction';

const WorkoutLevel = ({ levelData = {}, movementWorkoutList }) => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState('1');
  const [workoutData, setWorkoutData] = useState([]);
  const [pushups, setPushups] = useState(levelData?.pushups);
  const [squats, setSquats] = useState(levelData?.squats);
  const [timeBar, setTimeBar] = useState({
    days: ['MON', 'WED', 'SAT'],
    week: [1, 2],
    month: [1, 2],
  });
  const handleTime = () => {
    const structure = {
      timeBar: true,
      workout: false,
    };
    setWorkoutData([...workoutData, structure]);
  };
  const handleWorkout = () => {
    const structure = {
      timeBar: false,
      workout: true,
    };
    setWorkoutData([...workoutData, structure]);
    // {
    //   "level": 2,
    //     "name": "first workout",
    //     "description": "description",
    //     "days": [1, 2],
    //     "weeks": [2, 4]
    // }
    dispatch(
      addMovementWorkout({
        level: levelData.id,
        name: 'workout',
        description: 'description',
        days: [1, 2],
        weeks: [2, 5],
      }),
    );
  };
  const handlePushups = ({ target: { name, value } }) => {
    setPushups(value);
  };
  const handleSquats = ({ target: { name, value } }) => {
    setSquats(value);
  };

  const handleDeleteWorkout = id => {
    dispatch(deleteMovementWorkout(id));
  };

  const handleUpdateWorkout = type => () => {
    const dataKey = Object.keys(type)[0];
    if (levelData[dataKey] !== type[dataKey]) dispatch(updateMovementWorkoutLevel({ id: levelData.id, payload: type }));
  };

  return (
    <>
      <div className="mainLevelWrapper">
        <div className="topInputs">
          <Form.Group className="workoutInput mb-2">
            <Form.Label className="p-0">Number of pushups</Form.Label>
            <Form.Control
              type="number"
              onBlur={handleUpdateWorkout({ pushups: pushups })}
              placeholder="5"
              id="pushups"
              value={pushups}
              onChange={handlePushups}
            />
          </Form.Group>

          <Form.Group className="workoutInput mb-2">
            <Form.Label className="p-0">Number of squat jumps</Form.Label>
            <Form.Control
              type="number"
              onBlur={handleUpdateWorkout({ squats: squats })}
              placeholder="5"
              id="squats"
              value={squats}
              onChange={handleSquats}
            />
          </Form.Group>
        </div>

        <div className="workoutAccordion">
          {movementWorkoutList &&
            movementWorkoutList.map((workout, index) => {
              if (levelData.id === workout.level) {
                return (
                  <Accordion defaultActiveKey="0" activeKey={activeKey} key={index}>
                    <Card className={`border-0`}>
                      <Card.Header className={`accordionWorkout`}>
                        <MoveIcon />
                        <CustomToggleExercises setActiveKey={setActiveKey} eventKey={index} activeKey={activeKey}>
                          &nbsp; {workout.name}
                        </CustomToggleExercises>
                        <DropdownButton title="" drop={`start`} className={`menuDots`}>
                          <Dropdown.Item onClick={() => handleDeleteWorkout(workout.id)}>Delete</Dropdown.Item>
                        </DropdownButton>
                      </Card.Header>

                      <Accordion.Collapse eventKey={index}>
                        <>
                          <WorkoutCollapse workout={workout} />
                        </>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              }
            })}

          <div className="bg-white px-3 py-2 borderBottom">
            {/*<Button className="whiteBtn" onClick={handleTime}>*/}
            {/*  + Add time selection*/}
            {/*</Button>*/}
            <Button className="whiteBtn ms-0" onClick={handleWorkout}>
              + Add Workout
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default WorkoutLevel;
