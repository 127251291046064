import React from 'react';
import './InputTitle.css';
const InputTitle = (props) => {
  const classProps = props.className || '';
  return (
    <>
      <span className={`inputTitle ${classProps}`}>{props.children}</span>
    </>
  );
};
export default InputTitle;
