import React, { useState } from 'react';

import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import InputsearchCss from './InputsearchCss';

const InputWithSearch = ({ defaultValue = '', placeholder = '', onSubmit }) => {
  const classes = InputsearchCss();

  const [ text, setText ] = useState(defaultValue);

  const onChangeHandle = event => {
    setText(event.target.value);
  }

  const onSubmitHandle = event => {
    event.preventDefault();
    onSubmit(text);
  }

  return (
    <>
      <Paper
        component="form"
        onSubmit={onSubmitHandle}
        classes={{
          root: classes.textField,
        }}
      >
        <InputBase
          value={text}
          onChange={onChangeHandle}
          placeholder={placeholder}
          inputProps={{
            'aria-label': placeholder,
          }}
        />
        <IconButton type="submit" sx={{ p: '10px' }} aria-label="search" className={`p-2`}>
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.27001 14.5392C8.88302 14.5389 10.4495 13.9989 11.7202 13.0053L15.715 17L17 15.7151L13.0051 11.7204C13.9993 10.4498 14.5396 8.88296 14.54 7.26962C14.54 3.26133 11.2785 0 7.27001 0C3.26151 0 0 3.26133 0 7.26962C0 11.2779 3.26151 14.5392 7.27001 14.5392ZM7.27001 1.8174C10.2771 1.8174 12.7225 4.26272 12.7225 7.26962C12.7225 10.2765 10.2771 12.7218 7.27001 12.7218C4.26295 12.7218 1.8175 10.2765 1.8175 7.26962C1.8175 4.26272 4.26295 1.8174 7.27001 1.8174Z"
              fill="#4C443F"
            />
            <path
              d="M8.55316 5.98471C8.89758 6.33002 9.08751 6.78619 9.08751 7.26962H10.905C10.9058 6.79203 10.8119 6.31902 10.6288 5.87793C10.4457 5.43683 10.177 5.03641 9.83814 4.69981C8.46229 3.32585 6.07682 3.32585 4.70188 4.69981L5.98503 5.98653C6.67568 5.29773 7.86615 5.29955 8.55316 5.98471Z"
              fill="#4C443F"
            />
          </svg>
        </IconButton>
      </Paper>
    </>
  );
};

export default InputWithSearch;
