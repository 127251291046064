import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from '@material-ui/core/Button';
import QuizAns from './QuizAns';
import { useDispatch } from 'react-redux';
import { createAnswer, editQuestion } from '../../redux/actions/QuizzAction';
import InputTitle from '../UI/InputTitle';

const QuizLevelTwo = ({ questionData = {}, setQuestionText, getQuestionData, setQuestionData, qesIndex }) => {
  const dispatch = useDispatch();
  const { text, options, id: questionId, order } = questionData;
  const handleChangeQuestion = ({ target: { value } }) => {
    setQuestionText(value);
    const newAnswers = [...getQuestionData];
    newAnswers[qesIndex].text = value;
    setQuestionData(newAnswers);
  };
  const [ansOptions, setAnsOption] = useState(options);

  useEffect(() => {
    setAnsOption(options);
  }, [options]);

  // const handleAddExistingAnswer = (id, option) => () => {
  //     setAnsOption(prev => [
  //         ...prev,
  //         { text: '', order: prev.length + 1, question: questionData.id }
  //     ]);
  // };
  const handleUpdateQuestion = () => {
    const payload = {
      text,
      // question_type: 'multiple_choice',
      // order
    };
    dispatch(editQuestion({ data: payload, questionId }));
  };

  const handleAddAns = () => {
    const payload = {
      text: '',
      order: options.length ? options[options.length - 1].order + 1 : 1,
    };
    dispatch(createAnswer({ questionId, payload }));
  };
  const shortAnsOptions = ansOptions?.sort(function (a, b) {
    return parseFloat(a.order) - parseFloat(b.order);
  });
  return (
    <>
      <div className={`newVideoWrapper pt-3 borderBottom`}>
        <div className={`d-flex align-item-center`}>
          <InputTitle className={`me-3 mb-3`}>Question</InputTitle>
        </div>
        <div className={`w-100`}>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Question"
              name="questionText"
              value={text}
              onChange={handleChangeQuestion}
              // disabled={!addNewQuestion}
              onBlur={handleUpdateQuestion}
            />
          </Form.Group>
        </div>
      </div>
      <div className={`ans`}>
        {ansOptions &&
          shortAnsOptions.map((answer, index) => {
            return (
              <QuizAns
                ansOptions={ansOptions}
                setAnsOption={setAnsOption}
                isEdit={true}
                answer={answer}
                ansIndex={index}
                key={answer.id}
              />
            );
          })}
      </div>

      <div className={`p-3`}>
        <Button className={`whiteBtn px-4`} onClick={handleAddAns}>
          + Add Answer
        </Button>
      </div>
    </>
  );
};
export default QuizLevelTwo;
