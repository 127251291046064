import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from '@material-ui/core/Button';
import PlansRightContent from '../../components/Plans/RightComponets/PlansRightContent';
import PlansLeftContent from '../../components/Plans/LeftComponets/PlansLeftContent';
import { useDispatch, useSelector } from 'react-redux';
import {
    getUserCorelation,
    getUserType
} from '../../redux/actions/PlansAction';
import { getQuestion } from '../../redux/actions/QuizzAction';
import Loader from '../../components/Loader/Loader';

export default function Plans() {
    const navigation = ['Plan Management'];
    const dispatch = useDispatch();
    const [activeScreen, setActiveScreen] = useState('SelfCare');
    const [loading, setLoading] = useState(true);
    const {
        getUser: { success: getUserSuccess },
        getUserCorelation: { loading: getCorelationLoading }
    } = useSelector(({ plan }) => plan);
    useEffect(() => {
        dispatch(getUserType());
        dispatch(getQuestion());
        dispatch(getUserCorelation());
        // dispatch(
        //   updateUserCorelation({
        //     id: 34,
        //     data: {
        //       responses: [
        //         {
        //           question: 66,
        //           option: 130,
        //         },
        //         {
        //           question: 66,
        //           option: 131,
        //         },
        //       ],
        //       user_type: 5,
        //     },
        //   }),
        // );
    }, []);
    useEffect(() => {
        if (getUserSuccess) {
            setLoading(false);
        }
    }, [getUserSuccess]);
    return (
        <div>
            {loading ? (
                <div>
                    <Loader className="fullPageLoader" />
                </div>
            ) : (
                <>
                    <Breadcrumb navigation={navigation} className={'mb-0'}>
                        {/* <Button className={`CancelBtn min200`}>Cancel</Button> */}
                        {/* <Button className={`SaveBtn min200`}>
                            Save changes
                        </Button> */}
                    </Breadcrumb>
                    {/*<Hero />*/}
                    <Row>
                        <Col lg={7} className="pt-3">
                            <PlansLeftContent
                                setActiveScreen={setActiveScreen}
                            />
                        </Col>

                        <Col lg={5} className="pt-3">
                            <PlansRightContent activeScreen={activeScreen} />
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}
