import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DefaultLightBox from '../../../UI/DefaultLightBox';
import './exerciseLibrary.scss';
import InputWithSearch from '../../../InputWithSearch/InputWithSearch';
import { Accordion } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CustomToggleExercises from '../../../Exercises/ExercisesAccordionUi/CustomToggleExercises';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import CommonlyUsed from './CommonlyUsed';
import ExerciseComponents from './ExerciseComponents';
import AddNewCategory from './AddNewCategory';
const ExerciseLibrary = () => {
  const [activeKey, setActiveKey] = useState('1');
  const [toggleCategrory, setToggleCategrory] = useState(false);
  const data = [
    {
      id: 1,
      title: 'Commonly used',
      data: [
        { id: 1, title: 'Rest period' },
        { id: 2, title: 'Give a rating' },
      ],
    },
    {
      id: 2,
      title: 'Exercise Category',
      data: [
        { id: 1, title: 'Exercise Name 1' },
        { id: 2, title: 'Exercise Name 2' },
        { id: 3, title: 'Exercise Name 3' },
      ],
    },
    {
      id: 3,
      title: 'On demand exercises',
      data: [
        { id: 1, title: 'Exercise Name 1' },
        { id: 2, title: 'Exercise Name 2' },
        { id: 3, title: 'Exercise Name 3' },
      ],
    },
    {
      id: 4,
      title: 'On Demand exercises',
      data: [
        { id: 1, title: 'Exercise Name 1' },
        { id: 2, title: 'Exercise Name 2' },
        { id: 3, title: 'Exercise Name 3' },
      ],
    },
  ];

  function viewScreen(title, data) {
    switch (title) {
      case 'Commonly used':
        return <CommonlyUsed data={data} />;
      default:
        return <ExerciseComponents data={data} />;
    }
  }
  const handleAddToggle = () => {
    setToggleCategrory((prevState) => !prevState);
  };
  return (
    <DefaultLightBox>
      <Row className={`p-3 relative align-item-center`}>
        <Col>
          <p className="m-0">Exercise library</p>
        </Col>
        <Col className={`secondaryText text-right`}>
          {/*<span className="pr-3">Add new exercise</span>*/}
          <span className="addLink" onClick={handleAddToggle}>
            {toggleCategrory ? 'Close' : 'Add new category'}
          </span>
        </Col>
        <Col md={12} className="pt-3">
          {toggleCategrory ? (
            <AddNewCategory handleAddToggle={handleAddToggle} />
          ) : (
            <InputWithSearch placeholder={'Search exercises'} />
          )}
        </Col>
      </Row>

      <div className="exerciseLibrary">
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
          {data.map((data, index) => (
            <Card className={`border-0`} key={index}>
              <Card.Header className={`exerciseLevelOne`}>
                <CustomToggleExercises setActiveKey={setActiveKey} eventKey={index} activeKey={activeKey}>
                  &nbsp; {data.title}
                </CustomToggleExercises>
                <div>
                  <span>{data.data.length} item</span>
                  <DropdownButton title="" drop={`start`} className={`menuDots`}>
                    <Dropdown.Item href="#">Action</Dropdown.Item>
                  </DropdownButton>
                </div>
              </Card.Header>

              <Accordion.Collapse eventKey={index}>
                <>{viewScreen(data.title, data.data)}</>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>
    </DefaultLightBox>
  );
};
export default ExerciseLibrary;
