import React, { useState } from 'react';

import ChatMessage from './ChatMessage';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Button from '@material-ui/core/Button';

import './userFeedback.scss';
import Search from '../../../assets/icons/search.svg';

const UserFeedback = ({ data, primaryUser, secondaryUser, onMessageSend }) => {
  const [ search, setSearch ] = useState('');

  const onSearchChangeHandle = event => {
    setSearch(event.target.value);
  }

  return (
    <div className="userFeedbackWrapper">
      <div className="feedbackWrapper px-3">
        <p>Feedback</p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vel libero non nunc ultrices iaculis a luctus
          massa. Nulla eleifend, orci eget ultrices mattis, nibh mi congue purus
        </p>
      </div>
      <div className="d-flex flex-column flex-grow-1 p-3 overflow-hidden">
        <Row className="searchInputWrapper">
          <Col>
            <Form.Group className="inputBoxWrapper">
              <Form.Control type="text" placeholder="Search messages" name="search" onChange={onSearchChangeHandle} />
              <Button className="p-0 iconAction">
                <img src={Search} alt="search" />
              </Button>
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex flex-column flex-grow-1 overflow-hidden">
          {data && <ChatMessage data={data} primaryUser={primaryUser} secondaryUser={secondaryUser} search={search} onMessageSend={onMessageSend} />}
        </Row>
      </div>
    </div>
  );
};

export default UserFeedback;
