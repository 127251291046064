import React from 'react';
import Form from 'react-bootstrap/Form';
import './nutritionPlansCollapse.scss';
import MoveIcon from '../../../../assets/icons/MoveIcon';
import MessageIcon from '../../../../assets/icons/MessageIcon';
const NutritionPlansCollapse = () => {
  const data = [{ id: 1, title: 'Sleep' }];
  return (
    <>
      <div className="nutritionPlanFormWrapper">
        <Form.Group className="mb-3" controlId="habit">
          <Form.Label>Habit text</Form.Label>
          <Form.Control type="text" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="video">
          <Form.Label>Attach video</Form.Label>
          <Form.Control type="text" />
        </Form.Group>

        <Form.Group controlId="habit">
          <Form.Label>Objective notes</Form.Label>
          <Form.Control as="textarea" style={{ height: '60px' }} />
        </Form.Group>
      </div>
      <div className="nutritionPlanTableWrapper borderBottom">
        <table className="w-100">
          <tr className="workoutTableTitle">
            <td>Habit</td>
            <td>Type</td>
            <td>Target</td>
            <td>Rate</td>
          </tr>
          {data &&
            data.map((data, index) => (
              <tr className={`nutritionPlanTableRow`} key={index}>
                <td>
                  <div className="nutritionName">
                    <MoveIcon />
                    <Form.Check type="checkbox" className="ps-2" />
                    <span className="ps-2">{data.title}</span>
                  </div>
                </td>
                <td>
                  <Form.Select size="sm" className="dropdown">
                    <option></option>
                  </Form.Select>
                </td>
                <td className="d-flex">
                  <Form.Select size="sm" className="dropdown">
                    <option>Lorem ipsum ...</option>
                  </Form.Select>
                  <Form.Select size="sm" className="dropdown ms-2">
                    <option>Lorem ipsum ...</option>
                  </Form.Select>
                </td>
                <td>
                  <MessageIcon />
                </td>
              </tr>
            ))}
        </table>
      </div>
    </>
  );
};
export default NutritionPlansCollapse;
