import React from 'react';
import './exercisesTracked.scss';
import ProgressView from '../../../../UI/ProgressView/ProgressView';
const ExercisesTracked = () => {
  const data = [
    { id: 1, value: 87, title: '1 - Exercise name' },
    { id: 2, value: 12, title: '2 - Lorem ipsum dolor sit amet' },
    { id: 3, value: 45, title: '3 - Lorem ipsum dolor sit amet' },
    { id: 4, value: 65, title: '4 - Cardio' },
  ];
  return (
    <>
      {data &&
        data.map((data, index) => (
          <div className="exercisesTrackedWrapper" key={index}>
            <div className="chart">
              <ProgressView value={data.value} />
            </div>
            <div className="nameChart">{data.title}</div>
          </div>
        ))}
    </>
  );
};
export default ExercisesTracked;
