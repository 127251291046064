import React from 'react';
import './loader.scss';
const Loader = ({ className = '' }) => {
  return (
    <div className={className}>
      <div className={`lds-roller`}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
export default Loader;
