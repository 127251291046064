import React, { useEffect, useState } from 'react';
import MoveIcon from '../../../assets/icons/MoveIcon';
import DateIcon from '../../../assets/icons/DateIcon';
import { split } from 'lodash';
import './daysWeekBar.scss';
import DaysPickerComponents from './DaysPickerComponents';
import { Modal } from 'react-bootstrap';
import WeekPickerComponents from './WeekPickerComponents';
import MonthPickerComponents from './MonthPickerComponents';
const DaysWeekBar = ({ days = [], week = [], month = [], className = '', setTimeBar = () => {} }) => {
  const [show, setShow] = useState(false);
  const [calendar, setCalendar] = useState('');
  const [weekDays, setWeekDays] = useState([
    { title: 'MON', isSelect: false },
    { title: 'TUE', isSelect: false },
    { title: 'WED', isSelect: false },
    { title: 'THU', isSelect: false },
    { title: 'FRI', isSelect: false },
    { title: 'SAT', isSelect: false },
    { title: 'SUN', isSelect: false },
  ]);
  useEffect(() => {
    setWeekDays(
      weekDays.map((day, i) => {
        if (week.includes(i)) {
          return { ...day, isSelect: true };
        }
        return day;
      }),
    );
  }, [week]);
  const [weekNumber, setWeekNumber] = useState([
    { title: 1 },
    { title: 2 },
    { title: 3 },
    { title: 4 },
    { title: 5 },
    { title: 6 },
    { title: 7 },
    { title: 8 },
    { title: 9 },
    { title: 10 },
  ]);
  const [selectedWeekNumber, setSelectedWeekNumber] = useState(days);
  const [pageIndex, setPageIndex] = useState(0);

  // const [monthNumber, setMonthNumber] = useState([
  //   { title: 1, isSelect: true },
  //   { title: 2, isSelect: false },
  //   { title: 3, isSelect: false },
  //   { title: 4, isSelect: false },
  //   { title: 5, isSelect: true },
  //   { title: 6, isSelect: true },
  //   { title: 7, isSelect: false },
  //   { title: 8, isSelect: false },
  //   { title: 9, isSelect: false },
  //   { title: 10, isSelect: false },
  //   { title: 11, isSelect: false },
  //   { title: 12, isSelect: false },
  // ]);

  const handleClose = () => setShow(false);

  function handleDays() {
    setCalendar('DaysPickerComponents');
    setShow(true);
  }

  function handleWeek() {
    setCalendar('WeekPickerComponents');
    setShow(true);
  }

  function handleMonth() {
    setCalendar('MonthPickerComponents');
    setShow(true);
  }

  return (
    <>
      <div className={`timeWrapper ${className}`}>
        <div className="timeWrapperText">
          <div className="moveIcon">
            <MoveIcon />
          </div>
          <div className="daysTitle pd-2" onClick={handleDays}>
            days:
            {weekDays.map((days) => days.isSelect && `${days.title}, `)}
          </div>
          <div className="weekTitle pe-2" onClick={handleWeek}>
            Week:
            {selectedWeekNumber.map((days) => `${days}, `)}
          </div>
          {/*<div className="monthTitle" onClick={handleMonth}>*/}
          {/*  Month:*/}
          {/*  {monthNumber.map((days) => days.isSelect && `${days.title}, `)}*/}
          {/*</div>*/}
        </div>
        <div className="text-end pe-2">
          <DateIcon />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={'bgTransparent border-0'}
      >
        <Modal.Body className="p-0 bgTransparent">
          {calendar === 'DaysPickerComponents' && (
            <DaysPickerComponents handleClose={handleClose} weekDays={weekDays} setWeekDays={setWeekDays} />
          )}

          {calendar === 'WeekPickerComponents' && (
            <WeekPickerComponents
              handleClose={handleClose}
              weekNumber={weekNumber}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              selectedWeekNumber={selectedWeekNumber}
              setSelectedWeekNumber={setSelectedWeekNumber}
            />
          )}
          {/*{calendar === 'MonthPickerComponents' && (*/}
          {/*  <MonthPickerComponents*/}
          {/*    handleClose={handleClose}*/}
          {/*    monthNumber={monthNumber}*/}
          {/*    setMonthNumber={setMonthNumber}*/}
          {/*  />*/}
          {/*)}*/}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DaysWeekBar;
