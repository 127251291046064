import React from 'react';
import MoveIcon from '../../../../assets/icons/MoveIcon';
import Clock from '../../../../assets/icons/Clock';
import './commonlyUsed.scss';
const CommonlyUsed = ({ data }) => {
  return (
    <>
      {data &&
        data.map((data, index) => (
          <div className="commonlyUsed" key={index}>
            <div className="d-flex align-item-center">
              <MoveIcon />
              <p className="m-0">{data.title}</p>
            </div>
            <hr />
            <div>
              <Clock />
            </div>
          </div>
        ))}
    </>
  );
};
export default CommonlyUsed;
