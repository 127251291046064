import React from 'react';
import DummyImg from '../../../../../assets/images/bodyImg.png';
import './savedGoals.scss';
const SavedGoals = () => {
  const data = ['Front', 'Back', 'Side'];
  return (
    <div className="saveGoalsTable w-100">
      <table className="w-100">
        <tr className="saveGoalsTitle">
          <td>Body position</td>
          <td>Starting</td>
          <td>most recent</td>
        </tr>
        {data.map((data, index) => (
          <tr key={index}>
            <td>{data}</td>
            <td>
              <img src={DummyImg} />
            </td>
            <td>
              <img src={DummyImg} />
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};
export default SavedGoals;
