import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Axios } from '../../api/axios';
import { getSimplifiedError } from '../../utils/error';
import { toast } from 'react-toastify';

import {
  GET_MOVEMENT_TYPES_REQUEST,
  GET_MOVEMENT_TYPES_SUCCESS,
  GET_MOVEMENT_TYPES_ERROR,

  UPDATE_MOVEMENT_TYPE_REQUEST,
  UPDATE_MOVEMENT_TYPE_SUCCESS,
  UPDATE_MOVEMENT_TYPE_ERROR,
} from '../reducers/MovementTypesReducer';

async function getMovementTypes(payload) {
  return await Axios.get('/user_movement_type/', payload);
}

function* handleGetMovementTypes({ payload }) {
  try {
    const response = yield call(getMovementTypes, payload);

    if (response) {
      yield put({
        type: GET_MOVEMENT_TYPES_SUCCESS,
        data: response
      });
    }
  } catch (error) {
    yield put({
      type: GET_MOVEMENT_TYPES_ERROR,
      error: getSimplifiedError(error)
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}


async function updateMovementType(payload) {
  const formData = new FormData();

  if (payload.imageRaw)
    formData.append("image", payload.imageRaw);

  if (payload.description)
    formData.append("description", payload.description);

  return await Axios.patch(`/user_movement_type/${payload.id}/`, formData);
}

function* handleUpdateMovementType({ payload }) {
  try {
    const response = yield call(updateMovementType, payload);

    if (response) {
      yield put({
        type: UPDATE_MOVEMENT_TYPE_SUCCESS,
        data: response
      });
    }
  }

  catch(error) {
    yield put({
      type: GET_MOVEMENT_TYPES_ERROR,
      error: getSimplifiedError(error)
    });

    if (error) {
      toast(getSimplifiedError(error), { type: 'error' });
    }
  }
}

export default all([
  takeLatest(GET_MOVEMENT_TYPES_REQUEST, handleGetMovementTypes),
  takeLatest(UPDATE_MOVEMENT_TYPE_REQUEST, handleUpdateMovementType),
]);
