import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DefaultLightBox from '../../UI/DefaultLightBox';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';

import CustomToggle from '../../QuizAccordionUi/CustomToggleQuiz';
import QuestionsPicker from '../../QuestionsDropdown';
import PlansManagementWrapper from './PlansManagementWrapper';

import './Plans.scss';

const PlansLeftContent = ({ setActiveScreen }) => {
  const [activeKey, setActiveKey] = useState('1'); //for accordion

  const {
    getUser: { data },
  } = useSelector(({ plan }) => plan);

  const [userType, setUserType] = useState(null);

  useEffect(() => {
    if (data) {
      setUserType(data);
    }
  }, [data]);

  return (
    <DefaultLightBox>
      <Row className={`p-3 relative`}>
        <Col>
          <p className="m-0">Exercise and nutrition</p>
        </Col>
        {/* <Col className={`text-end`}>
          <DropdownButton title="" drop={`start`} className={`menuDots right20`}>
            <Dropdown.Item href="#">Action</Dropdown.Item>
          </DropdownButton>
        </Col> */}
      </Row>

      <div className={`plansMain`}>
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
          {userType &&
            userType.map((data, index) => {
              const { name, id } = data;
              return (
                <Card className={`border-0`} key={index}>
                  <Card.Header className={`p-0 planLevelOne`}>
                    <div>
                      <CustomToggle setActiveKey={setActiveKey} eventKey={index} activeKey={activeKey}>
                        &nbsp;{name}
                      </CustomToggle>
                    </div>

                    <div className="d-flex align-item-center">
                      <QuestionsPicker id={id} userType={index} />
                    </div>
                  </Card.Header>

                  <Accordion.Collapse eventKey={index}>
                    <>
                      <PlansManagementWrapper setActiveScreen={setActiveScreen} userTypeId={id} />
                    </>
                  </Accordion.Collapse>
                </Card>
              );
            })}
        </Accordion>
      </div>
    </DefaultLightBox>
  );
};
export default PlansLeftContent;
