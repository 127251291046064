import * as React from 'react';

function SendIcon(props) {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.34 7.314l-14-7a3 3 0 00-4.08 3.9l2.4 5.37a1.06 1.06 0 010 .82l-2.4 5.37A3 3 0 003 19.994a3.14 3.14 0 001.35-.32l14-7a3 3 0 000-5.36h-.01zm-.89 3.57l-14 7a1 1 0 01-1.35-1.3l2.39-5.37c.03-.071.057-.145.08-.22h6.89a1 1 0 000-2H4.57a2 2 0 00-.08-.22L2.1 3.404a1 1 0 011.35-1.3l14 7a1 1 0 010 1.78z"
        fill="#4C443F"
      />
    </svg>
  );
}

export default SendIcon;
