import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Loader from '../../components/Loader/Loader';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import MessagesComponents from '../../components/CommunityChat/CommunityChat';

import { getUsers } from '../../redux/actions/UsersAction';

const CommunityChat = () => {
  const dispatch = useDispatch();

  const navigation = ['COMMUNITY CHAT'];
  const loading = false;

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  return (
    <div>
      {loading ? (
        <div>
          <Loader className="fullPageLoader" />
        </div>
      ) : (
        <>
          <Breadcrumb navigation={navigation} route="PLAN MANAGEMENT" className={'mb-0'} />
          <MessagesComponents />
        </>
      )}
    </div>
  );
};

export default CommunityChat;
