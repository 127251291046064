import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import './userChatList.scss';
import placeholder from '../../../assets/images/placeholder1.png';

function getDate(obj) {
  const format = 'DD.MM.YYYY';

  if (obj.dateTime)
    return moment(obj.dateTime).format(format);
  else if (obj.updatedAt)
    return moment(obj.updatedAt.seconds * 1000).format(format);
  else return '';
}

const UserChatList = ({ data, className = '' }) => {
  return (
    <Link className={`listWrapper ${className}`} to={`/messages/${data.secondaryUser.userID}`}>
      <div className="userImgWrapper">
        <img src={data.secondaryUser.userPicture || placeholder} width="40" height="40" alt="user images" />
      </div>
      <div className="userMessageWrapper">
        <p className="nameUser">{data.secondaryUser.userName} - {getDate(data)}</p>
        <p className="messageUser">{data.message}</p>
      </div>
      {/* <div className="userActionIcon">
        <DropdownButton title="" drop={`start`} className={`menuDots`}>
          <Dropdown.Item>Delete</Dropdown.Item>
        </DropdownButton>
      </div> */}
    </Link>
  );
};

export default UserChatList;
