import * as React from 'react';

function FilledArrow(props) {
  return (
    <svg width={7} height={6} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.366 5.5a1 1 0 01-1.732 0L.469 1.75a1 1 0 01.866-1.5h4.33a1 1 0 01.866 1.5L4.366 5.5z"
        fill="#AA957F"
      />
    </svg>
  );
}

export default FilledArrow;
