import { makeStyles } from '@material-ui/core';

const InputsearchCss = makeStyles((theme) => ({
  textField: {
    marginTop: '10px',
    '& .MuiInputBase-input': {
      fontSize: '12px',
      paddingLeft: '10px',
      color: '#4C443F',
    },
    '& .MuiInputBase-root': {
      width: 'calc(100% - 40px)',
    },
  },
}));

export default InputsearchCss;
