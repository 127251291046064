import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles } from '@material-ui/core/styles';
import './plansTable.css';

export default function PlansTable() {
  const {
    getPlansStatistic: { data },
  } = useSelector(({ statistics }) => statistics);

  const useStyles = makeStyles({
    TableContainer: {
      width: '0px',
    },
    table: {
      maxWidth: '580px',
      padding: '15px',
      fontFamily: 'Montserrat, serif',
      borderRadius: '10px 10px 0px 0px',
    },
    header: {
      textTransform: 'uppercase',
    },
    tableCell: {
      padding: '15px 20px',
      fontSize: '11px',
      fontWeigh: '500',
      backgroundColor: '#F4F3EE',
      color: '#4C443F',
    },
    userName: {
      color: '#62A8A4',
    },
    icon: {
      width: '10px',
      height: '13.5px',
    },
    tableData: {
      padding: '15px 20px',
      backgroundColor: '#FAF9F3',
      fontSize: '12px',
    },
  });

  const classes = useStyles();

  const RenderPlans = () => {
    const plans = [];

    for (const [key, value] of Object.entries(data)) {
      plans.push(
        <TableRow key={key}>
          <TableCell component="th" scope="row" className={`${classes.tableData}`}>
            {key}
          </TableCell>
          <TableCell align="left" className={`${classes.tableCell} ${classes.tableData}`} align="right">
            {value}
          </TableCell>
        </TableRow>
      );
    }

    return plans;
  }

  return (
    <TableContainer>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.tableCell} ${classes.header}`}>Plans</TableCell>
            <TableCell className={`${classes.tableCell} ${classes.header} ${classes.userName}`} align="right">
              <Link className="view-all-plans" to="/plans">
                View all Plans
              </Link>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {RenderPlans()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
