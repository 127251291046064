import React from 'react';
import MoveIcon from '../../../../../assets/icons/MoveIcon';
import Form from 'react-bootstrap/Form';
import './nutritionTableRow.scss';
import MessageIcon from '../../../../../assets/icons/MessageIcon';

const NutritionTableRow = props => {
    const {
        group = true,
        className: classes,
        title = 'Habit Title',
        onChangeHandler,
        onBlur,
        nutritionHabitData,
        handleUpdateHabit
    } = props;
    return (
        <tr className={`nutritionTableRow ${classes}`}>
            <td>
                <div className="nutritionName">
                    <MoveIcon />
                    {!group && <Form.Check type="checkbox" className="ps-2" />}

                    <span className="ps-2">{title}</span>
                </div>
            </td>
            <td>
                <Form.Select
                    onChange={e =>
                        handleUpdateHabit({
                            plan_type: e.target.value.toLowerCase()
                        })()
                    }
                    size="sm"
                    className="dropdown"
                >
                    <option
                        selected={nutritionHabitData?.plan_type == 'Number'}
                    >
                        Number
                    </option>
                    <option selected={nutritionHabitData?.plan_type == 'Time'}>
                        Time{' '}
                    </option>
                    <option selected={nutritionHabitData?.plan_type == 'Speed'}>
                        Speed{' '}
                    </option>
                    <option
                        selected={nutritionHabitData?.plan_type == 'Incline'}
                    >
                        Incline
                    </option>
                    <option
                        selected={nutritionHabitData?.plan_type == 'Distance'}
                    >
                        Distance
                    </option>
                </Form.Select>
            </td>
            <td>
                <Form.Control
                    type="text"
                    name="target"
                    value={nutritionHabitData?.target}
                    onChange={onChangeHandler}
                    onBlur={onBlur}
                    className="inputTarget"
                />
            </td>
            <td>
                <MessageIcon />
            </td>
        </tr>
    );
};
export default NutritionTableRow;
