import React, { useState } from 'react';
import DefaultLightBox from '../../../UI/DefaultLightBox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Accordion } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import './style.scss';
import CustomToggle from '../../../QuizAccordionUi/CustomToggleQuiz';
import ExerciseAndNutrition from './ExerciseAndNutrition/ExerciseAndNutrition';
import UserSelfCare from './UserSelfCare/UserSelfCare';
import SavedVideos from './SavedVideos/SavedVideos';
import SavedImages from './SavedImages/SavedImages';
import SavedGoals from './SavedGoals/SavedGoals';
import ExercisesTracked from './ExercisesTracked/ExercisesTracked';

const UserProfileLeftContent = () => {
  const [activeKey, setActiveKey] = useState('1'); //for accordion

  const data = [
    {
      id: 1,
      levels: 'level1',
      name: 'Exercise and nutrition: Weight loss',
    },
    {
      id: 2,
      levels: 'level2',
      name: 'Self-care: Sleep',
    },
    {
      id: 3,
      levels: 'level3',
      name: 'Quizz results',
    },
    {
      id: 4,
      levels: 'level4',
      name: 'Saved videos',
    },
    {
      id: 5,
      levels: 'level5',
      name: 'Saved images',
    },
    {
      id: 6,
      levels: 'level6',
      name: 'Saved goals',
    },
    {
      id: 7,
      levels: 'level7',
      name: 'Exercises tracked',
    },
    {
      id: 8,
      levels: 'level8',
      name: 'Messages',
    },
  ];

  const userAccrodianScreen = (name) => {
    switch (name) {
      case 'Exercise and nutrition: Weight loss':
        return <ExerciseAndNutrition />;
      case 'Self-care: Sleep':
        return <UserSelfCare />;
      case 'Saved videos':
        return <SavedVideos />;
      case 'Saved images':
        return <SavedImages />;
      case 'Saved goals':
        return <SavedGoals />;
      case 'Exercises tracked':
        return <ExercisesTracked />;
    }
  };

  return (
    <DefaultLightBox>
      <Row className={`p-3 relative`}>
        <Col>
          <span>User overview</span>
        </Col>
      </Row>

      <div className={`userOverview`}>
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
          {data &&
            data.map((data, index) => {
              const { name, levels, id } = data;
              return (
                <Card className={`border-0`} key={index}>
                  <Card.Header className={`p-0 userLevelOne`}>
                    <CustomToggle setActiveKey={setActiveKey} eventKey={index} activeKey={activeKey}>
                      &nbsp;{name}
                    </CustomToggle>
                    <div className="d-flex align-item-center">{/*<ArrowIcon />*/}</div>
                  </Card.Header>

                  <Accordion.Collapse eventKey={index}>
                    <div key={index}>{userAccrodianScreen(name)}</div>
                  </Accordion.Collapse>
                </Card>
              );
            })}
        </Accordion>
      </div>
    </DefaultLightBox>
  );
};
export default UserProfileLeftContent;
