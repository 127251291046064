import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import Button from '@material-ui/core/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import { createQuestion, deleteQuestion, getQuestion } from '../../redux/actions/QuizzAction';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Accordion } from 'react-bootstrap';
import QuizLevelTwo from '../../components/QuizAccordionUi/QuizLevelTwo';
import QuizQuestionLevelTwo from '../../components/QuizAccordionUi/QuizQuestionLevelTwo';
import DefaultLightBox from '../../components/UI/DefaultLightBox';
import './quizz.css';
import CustomToggleQuiz from '../../components/QuizAccordionUi/CustomToggleQuiz';
import Loader from '../../components/Loader/Loader';

const Quizz = () => {
  const navigation = ['Quizz setup'];
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState('1');
  const {
    getQuestion: { data, success: getQuestionSuccess },
    createQuestion: { success },
  } = useSelector(({ quizz }) => quizz);

  const [addNewQuestion, setAddNewQuestion] = useState(false);
  const [questionText, setQuestionText] = useState('');
  const [questionData, setQuestionData] = useState(data);
  const [answers, setAnswers] = useState([{ text: '', order: 1 }]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getQuestion());
  }, []);

  useEffect(() => {
    setQuestionData(data);
  }, [data]);
  useEffect(() => {
    if (getQuestionSuccess) setLoading(false);
  }, [getQuestionSuccess]);

  useEffect(() => {
    if (success) {
      setAnswers([{ text: '', order: 1 }]);
      setQuestionText('');
      setAddNewQuestion(false);
    }
  }, [success]);

  const handleSetAnswers = (value, index) => {
    const newAnswers = [...answers];
    newAnswers[index].text = value;
    setAnswers(newAnswers);
  };
  const handleAddAnswer = () => {
    setAnswers((prev) => [...prev, { text: '', order: prev.length + 1 }]);
  };

  const handleAddQuestion = () => {
    setActiveKey('0');
    setAddNewQuestion(true);
  };
  const handleSaveChanges = () => {
    const addQuestionPayload = {
      text: questionText,
      question_type: 'multiple_choice',
      order: 1,
      options: answers,
    };
    if (!!questionText && !!answers.length && addNewQuestion) {
      dispatch(createQuestion(addQuestionPayload));
    }
  };
  const handleDeleteQuestion = (id) => () => {
    dispatch(deleteQuestion({ id }));
  };

  const shortQuestionData = questionData?.sort(function (a, b) {
    return parseFloat(a.id) - parseFloat(b.id);
  });
  return (
    <>
      {loading ? (
        <div>{<Loader className="fullPageLoader" />}</div>
      ) : (
        <>
          <Breadcrumb navigation={navigation}>
            <Button className="CancelBtn min200">Cancel</Button>
            <Button className={`SaveBtn min200`} onClick={handleSaveChanges}>
              Save changes
            </Button>
          </Breadcrumb>

          <DefaultLightBox className={`quizzPage`}>
            <Row className={`p-3`}>
              <Col>
                <p>Quizz questions</p>
              </Col>
              <Col className={`text-end`}>
                <Button className={`whiteBtn px-4`} onClick={handleAddQuestion}>
                  + Add question
                </Button>{' '}
              </Col>
            </Row>
            <Row>
              <Accordion defaultActiveKey="0" activeKey={activeKey}>
                {addNewQuestion && (
                  <Card className={`border-0`}>
                    <Card.Header className={`p-0 QuizLevelOne`}>
                      <CustomToggleQuiz setActiveKey={setActiveKey} eventKey={`0`} activeKey={activeKey}>
                        &nbsp; Question
                      </CustomToggleQuiz>
                      <DropdownButton title="" drop={`start`} className={`menuDots`}>
                        <Dropdown.Item href="#">Delete</Dropdown.Item>
                      </DropdownButton>
                    </Card.Header>

                    <Accordion.Collapse eventKey="0">
                      <QuizQuestionLevelTwo
                        addNewQuestion={addNewQuestion}
                        setQuestionText={setQuestionText}
                        setAnswerText={handleSetAnswers}
                        answers={answers}
                        handleAddAnswer={handleAddAnswer}
                      />
                    </Accordion.Collapse>
                  </Card>
                )}

                {!!questionData.length &&
                  shortQuestionData.map((question, index) => {
                    return (
                      <Card className={`border-0`} key={question.id}>
                        <Card.Header className={`p-0 QuizLevelOne`}>
                          <CustomToggleQuiz setActiveKey={setActiveKey} eventKey={index} activeKey={activeKey}>
                            &nbsp; Question {index + 1}
                          </CustomToggleQuiz>
                          <DropdownButton title="" drop={`start`} className={`menuDots`}>
                            <Dropdown.Item href="#" onClick={handleDeleteQuestion(question.id)}>
                              Delete
                            </Dropdown.Item>
                          </DropdownButton>
                        </Card.Header>

                        <Accordion.Collapse eventKey={index}>
                          <QuizLevelTwo
                            questionData={question}
                            addNewQuestion={addNewQuestion}
                            setQuestionText={setQuestionText}
                            setActiveKey={setActiveKey}
                            getQuestionData={questionData}
                            setQuestionData={setQuestionData}
                            qesIndex={index}
                          />
                        </Accordion.Collapse>
                      </Card>
                    );
                  })}
              </Accordion>
            </Row>
          </DefaultLightBox>
        </>
      )}
    </>
  );
};
export default Quizz;
