import React from 'react';
import SelfCare from './SelfCare/SelfCare';
import ExerciseLibrary from './ExerciseLibrary/ExerciseLibrary';
import NutritionLibrary from './NutritionLibrary/NutritionLibrary';
import MorningMomentsLibrary from './MorningMomentsLibrary/MorningMomentsLibrary';
import SelfCareLibrary from './SelfCareLibrary/SelfCareLibrary';

const PlansRightContent = ({ activeScreen }) => {
  switch (activeScreen) {
    case 'SelfCare':
      return <SelfCare />;
    case 'Movement':
      return <ExerciseLibrary />;
    case 'Nutrition':
      return <NutritionLibrary />;
    case 'Morning moments':
      return <MorningMomentsLibrary />;
    case 'Body Composition':
      return <SelfCareLibrary />;
    default:
      return <SelfCare />;
  }
};
export default PlansRightContent;
