export const RESET_BLOCK_PLANS = 'RESET_BLOCK_PLANS';
export const RESET_FLAGS_PLANS = 'RESET_FLAGS_PLANS';

export const GET_USER_TYPE_REQUEST = 'GET_USER_TYPE_REQUEST';
export const GET_USER_TYPE_SUCCESS = 'GET_USER_TYPE_SUCCESS';
export const GET_USER_TYPE_ERROR = 'GET_USER_TYPE_ERROR';

/* Workout */
export const GET_MOVEMENT_WORKOUT_REQUEST = 'GET_MOVEMENT_WORKOUT_REQUEST';
export const GET_MOVEMENT_WORKOUT_SUCCESS = 'GET_MOVEMENT_WORKOUT_SUCCESS';
export const GET_MOVEMENT_WORKOUT_ERROR = 'GET_MOVEMENT_WORKOUT_ERROR';

export const GET_DETAIL_MOVEMENT_WORKOUT_REQUEST = 'GET_DETAIL_MOVEMENT_WORKOUT_REQUEST';
export const GET_DETAIL_MOVEMENT_WORKOUT_SUCCESS = 'GET_DETAIL_MOVEMENT_WORKOUT_SUCCESS';
export const GET_DETAIL_MOVEMENT_WORKOUT_ERROR = 'GET_DETAIL_MOVEMENT_WORKOUT_ERROR';

export const POST_MOVEMENT_WORKOUT_REQUEST = 'POST_MOVEMENT_WORKOUT_REQUEST';
export const POST_MOVEMENT_WORKOUT_SUCCESS = 'POST_MOVEMENT_WORKOUT_SUCCESS';
export const POST_MOVEMENT_WORKOUT_ERROR = 'POST_MOVEMENT_WORKOUT_ERROR';

export const UPDATE_MOVEMENT_WORKOUT_REQUEST = 'UPDATE_MOVEMENT_WORKOUT_REQUEST';
export const UPDATE_MOVEMENT_WORKOUT_SUCCESS = 'UPDATE_MOVEMENT_WORKOUT_SUCCESS';
export const UPDATE_MOVEMENT_WORKOUT_ERROR = 'UPDATE_MOVEMENT_WORKOUT_ERROR';

export const DELETE_MOVEMENT_WORKOUT_REQUEST = 'DELETE_MOVEMENT_WORKOUT_REQUEST';
export const DELETE_MOVEMENT_WORKOUT_SUCCESS = 'DELETE_MOVEMENT_WORKOUT_SUCCESS';
export const DELETE_MOVEMENT_WORKOUT_ERROR = 'DELETE_MOVEMENT_WORKOUT_ERROR';
/* Workout End*/

/* MOVEMENT_LEVEL */
export const GET_MOVEMENT_WORKOUT_LEVEL_REQUEST = 'GET_MOVEMENT_WORKOUT_LEVEL_REQUEST';
export const GET_MOVEMENT_WORKOUT_LEVEL_SUCCESS = 'GET_MOVEMENT_WORKOUT_LEVEL_SUCCESS';
export const GET_MOVEMENT_WORKOUT_LEVEL_ERROR = 'GET_MOVEMENT_WORKOUT_LEVEL_ERROR';

export const GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_REQUEST = 'GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_REQUEST';
export const GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_SUCCESS = 'GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_SUCCESS';
export const GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_ERROR = 'GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_ERROR';

export const POST_MOVEMENT_WORKOUT_LEVEL_REQUEST = 'POST_MOVEMENT_WORKOUT_LEVEL_REQUEST';
export const POST_MOVEMENT_WORKOUT_LEVEL_SUCCESS = 'POST_MOVEMENT_WORKOUT_LEVEL_SUCCESS';
export const POST_MOVEMENT_WORKOUT_LEVEL_ERROR = 'POST_MOVEMENT_WORKOUT_LEVEL_ERROR';

export const UPDATE_MOVEMENT_WORKOUT_LEVEL_REQUEST = 'UPDATE_MOVEMENT_WORKOUT_LEVEL_REQUEST';
export const UPDATE_MOVEMENT_WORKOUT_LEVEL_SUCCESS = 'UPDATE_MOVEMENT_WORKOUT_LEVEL_SUCCESS';
export const UPDATE_MOVEMENT_WORKOUT_LEVEL_ERROR = 'UPDATE_MOVEMENT_WORKOUT_LEVEL_ERROR';

export const DELETE_MOVEMENT_WORKOUT_LEVEL_REQUEST = 'DELETE_MOVEMENT_WORKOUT_LEVEL_REQUEST';
export const DELETE_MOVEMENT_WORKOUT_LEVEL_SUCCESS = 'DELETE_MOVEMENT_WORKOUT_LEVEL_SUCCESS';
export const DELETE_MOVEMENT_WORKOUT_LEVEL_ERROR = 'DELETE_MOVEMENT_WORKOUT_LEVEL_ERROR';
/* MOVEMENT_LEVEL END */
/* MOVEMENT EXERCISE LIBRARY  CATEGORY */
export const GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST = 'GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST';
export const GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS = 'GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS';
export const GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR = 'GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR';

export const ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST = 'ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST';
export const ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS = 'ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS';
export const ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR = 'ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR';
/* MOVEMENT EXERCISE LIBRARY  CATEGORY End */

export const GET_MOVEMENT_WORKOUT_GOAL_REQUEST = 'GET_MOVEMENT_WORKOUT_GOAL_REQUEST';
export const GET_MOVEMENT_WORKOUT_GOAL_SUCCESS = 'GET_MOVEMENT_WORKOUT_GOAL_SUCCESS';
export const GET_MOVEMENT_WORKOUT_GOAL_ERROR = 'GET_MOVEMENT_WORKOUT_LEVEL_ERROR';

export const POST_MOVEMENT_WORKOUT_GOAL_REQUEST = 'POST_MOVEMENT_WORKOUT_GOAL_REQUEST';
export const POST_MOVEMENT_WORKOUT_GOAL_SUCCESS = 'POST_MOVEMENT_WORKOUT_GOAL_SUCCESS';
export const POST_MOVEMENT_WORKOUT_GOAL_ERROR = 'POST_MOVEMENT_WORKOUT_GOAL_ERROR';

export const UPDATE_MOVEMENT_WORKOUT_GOAL_REQUEST = 'UPDATE_MOVEMENT_WORKOUT_GOAL_REQUEST';
export const UPDATE_MOVEMENT_WORKOUT_GOAL_SUCCESS = 'UPDATE_MOVEMENT_WORKOUT_GOAL_SUCCESS';
export const UPDATE_MOVEMENT_WORKOUT_GOAL_ERROR = 'UPDATE_MOVEMENT_WORKOUT_GOAL_ERROR';

export const DELETE_MOVEMENT_WORKOUT_GOAL_REQUEST = 'DELETE_MOVEMENT_WORKOUT_GOAL_REQUEST';
export const DELETE_MOVEMENT_WORKOUT_GOAL_SUCCESS = 'DELETE_MOVEMENT_WORKOUT_GOAL_SUCCESS';
export const DELETE_MOVEMENT_WORKOUT_GOAL_ERROR = 'DELETE_MOVEMENT_WORKOUT_LEVEL_ERROR';

export const CREATE_USER_CORELATION_REQUEST = 'CREATE_USER_CORELATION_REQUEST';
export const CREATE_USER_CORELATION_SUCCESS = 'CREATE_USER_CORELATION_SUCCESS';
export const CREATE_USER_CORELATION_ERROR = 'CREATE_USER_CORELATION_ERROR';

export const ADD_NEW_USER_CORELATION_REQUEST = 'ADD_NEW_USER_CORELATION_REQUEST';
export const ADD_NEW_USER_CORELATION_SUCCESS = 'ADD_NEW_USER_CORELATION_SUCCESS';
export const ADD_NEW_USER_CORELATION_ERROR = 'ADD_NEW_USER_CORELATION_ERROR';

export const DELETE_USER_CORELATION_REQUEST = 'DELETE_USER_CORELATION_REQUEST';
export const DELETE_USER_CORELATION_SUCCESS = 'DELETE_USER_CORELATION_SUCCESS';
export const DELETE_USER_CORELATION_ERROR = 'DELETE_USER_CORELATION_ERROR';

export const GET_USER_CORELATION_REQUEST = 'GET_USER_CORELATION_REQUEST';
export const GET_USER_CORELATION_SUCCESS = 'GET_USER_CORELATION_SUCCESS';
export const GET_USER_CORELATION_ERROR = 'GET_USER_CORELATION_ERROR';

/*************************** Nutrition  *************************/

/** Nutrition Level */
export const GET_NUTRITION_LEVEL_REQUEST = 'GET_NUTRITION_LEVEL_REQUEST';
export const GET_NUTRITION_LEVEL_SUCCESS = 'GET_NUTRITION_LEVEL_SUCCESS';
export const GET_NUTRITION_LEVEL_ERROR = 'GET_NUTRITION_LEVEL_ERROR';

export const GET_DETAIL_NUTRITION_LEVEL_REQUEST = 'GET_DETAIL_NUTRITION_LEVEL_REQUEST';
export const GET_DETAIL_NUTRITION_LEVEL_SUCCESS = 'GET_DETAIL_NUTRITION_LEVEL_SUCCESS';
export const GET_DETAIL_NUTRITION_LEVEL_ERROR = 'GET_DETAIL_NUTRITION_LEVEL_ERROR';

export const POST_NUTRITION_LEVEL_REQUEST = 'POST_NUTRITION_LEVEL_REQUEST';
export const POST_NUTRITION_LEVEL_SUCCESS = 'POST_NUTRITION_LEVEL_SUCCESS';
export const POST_NUTRITION_LEVEL_ERROR = 'POST_NUTRITION_LEVEL_ERROR';

export const DELETE_NUTRITION_LEVEL_REQUEST = 'DELETE_NUTRITION_LEVEL_REQUEST';
export const DELETE_NUTRITION_LEVEL_SUCCESS = 'DELETE_NUTRITION_LEVEL_SUCCESS';
export const DELETE_NUTRITION_LEVEL_ERROR = 'DELETE_NUTRITION_LEVEL_ERROR';
/* Nutrition Level End */

/** Nutrition Plan */
export const GET_NUTRITION_PLAN_REQUEST = 'GET_NUTRITION_PLAN_REQUEST';
export const GET_NUTRITION_PLAN_SUCCESS = 'GET_NUTRITION_PLAN_SUCCESS';
export const GET_NUTRITION_PLAN_ERROR = 'GET_NUTRITION_PLAN_ERROR';

export const GET_DETAIL_NUTRITION_PLAN_REQUEST = 'GET_DETAIL_NUTRITION_PLAN_REQUEST';
export const GET_DETAIL_NUTRITION_PLAN_SUCCESS = 'GET_DETAIL_NUTRITION_PLAN_SUCCESS';
export const GET_DETAIL_NUTRITION_PLAN_ERROR = 'GET_DETAIL_NUTRITION_PLAN_ERROR';

export const POST_NUTRITION_PLAN_REQUEST = 'POST_NUTRITION_PLAN_REQUEST';
export const POST_NUTRITION_PLAN_SUCCESS = 'POST_NUTRITION_PLAN_SUCCESS';
export const POST_NUTRITION_PLAN_ERROR = 'POST_NUTRITION_PLAN_ERROR';

export const DELETE_NUTRITION_PLAN_REQUEST = 'DELETE_NUTRITION_PLAN_REQUEST';
export const DELETE_NUTRITION_PLAN_SUCCESS = 'DELETE_NUTRITION_PLAN_SUCCESS';
export const DELETE_NUTRITION_PLAN_ERROR = 'DELETE_NUTRITION_PLAN_ERROR';
/* Nutrition Plan End */

/** Nutrition Habit */
export const GET_NUTRITION_HABIT_REQUEST = 'GET_NUTRITION_HABIT_REQUEST';
export const GET_NUTRITION_HABIT_SUCCESS = 'GET_NUTRITION_HABIT_SUCCESS';
export const GET_NUTRITION_HABIT_ERROR = 'GET_NUTRITION_HABIT_ERROR';

export const GET_DETAIL_NUTRITION_HABIT_REQUEST = 'GET_DETAIL_NUTRITION_HABIT_REQUEST';
export const GET_DETAIL_NUTRITION_HABIT_SUCCESS = 'GET_DETAIL_NUTRITION_HABIT_SUCCESS';
export const GET_DETAIL_NUTRITION_HABIT_ERROR = 'GET_DETAIL_NUTRITION_HABIT_ERROR';

export const POST_NUTRITION_HABIT_REQUEST = 'POST_NUTRITION_HABIT_REQUEST';
export const POST_NUTRITION_HABIT_SUCCESS = 'POST_NUTRITION_HABIT_SUCCESS';
export const POST_NUTRITION_HABIT_ERROR = 'POST_NUTRITION_HABIT_ERROR';

export const UPDATE_NUTRITION_HABIT_REQUEST = 'UPDATE_NUTRITION_HABIT_REQUEST';
export const UPDATE_NUTRITION_HABIT_SUCCESS = 'UPDATE_NUTRITION_HABIT_SUCCESS';
export const UPDATE_NUTRITION_HABIT_ERROR = 'UPDATE_NUTRITION_HABIT_ERROR';

export const DELETE_NUTRITION_HABIT_REQUEST = 'DELETE_NUTRITION_HABIT_REQUEST';
export const DELETE_NUTRITION_HABIT_SUCCESS = 'DELETE_NUTRITION_HABIT_SUCCESS';
export const DELETE_NUTRITION_HABIT_ERROR = 'DELETE_NUTRITION_HABIT_ERROR';
/* Nutrition Habit End */
/*************************** Nutrition End *************************/

/** Morning Moment Habit */
export const GET_MORNING_MOMENT_REQUEST = 'GET_MORNING_MOMENT_REQUEST';
export const GET_MORNING_MOMENT_SUCCESS = 'GET_MORNING_MOMENT_SUCCESS';
export const GET_MORNING_MOMENT_ERROR = 'GET_MORNING_MOMENT_ERROR';

export const GET_DETAIL_MORNING_MOMENT_REQUEST = 'GET_DETAIL_MORNING_MOMENT_REQUEST';
export const GET_DETAIL_MORNING_MOMENT_SUCCESS = 'GET_DETAIL_MORNING_MOMENT_SUCCESS';
export const GET_DETAIL_MORNING_MOMENT_ERROR = 'GET_DETAIL_MORNING_MOMENT_ERROR';

export const POST_MORNING_MOMENT_REQUEST = 'POST_MORNING_MOMENT_REQUEST';
export const POST_MORNING_MOMENT_SUCCESS = 'POST_MORNING_MOMENT_SUCCESS';
export const POST_MORNING_MOMENT_ERROR = 'POST_MORNING_MOMENT_ERROR';

export const UPDATE_MORNING_MOMENT_REQUEST = 'UPDATE_MORNING_MOMENT_REQUEST';
export const UPDATE_MORNING_MOMENT_SUCCESS = 'UPDATE_MORNING_MOMENT_SUCCESS';
export const UPDATE_MORNING_MOMENT_ERROR = 'UPDATE_MORNING_MOMENT_ERROR';

export const DELETE_MORNING_MOMENT_REQUEST = 'DELETE_MORNING_MOMENT_REQUEST';
export const DELETE_MORNING_MOMENT_SUCCESS = 'DELETE_MORNING_MOMENT_SUCCESS';
export const DELETE_MORNING_MOMENT_ERROR = 'DELETE_MORNING_MOMENT_ERROR';
/*  Morning Moment End */

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getUser: { ...block, data: [] },
  createUserCorelation: { ...block },
  getUserCorelation: { ...block, data: [] },
  updateUserCorelation: { ...block },
  deleteUserCorelation: { ...block },

  movementWorkoutGoal: { ...block, data: [] },
  updateMovementWorkoutGoal: { ...block, data: [] },
  selectedMovementWorkoutGoal: { ...block, data: [] },
  addMovementWorkoutGoal: { ...block },
  deleteMovementWorkoutGoal: { ...block },

  movementExerciseLibraryCategory: { ...block, data: [] },
  addMovementExerciseLibraryCategory: { ...block, data: [] },

  movementWorkout: { ...block, data: [] },
  updateMovementWorkout: { ...block, data: [] },
  selectedMovementWorkout: { ...block, data: [] },
  addMovementWorkout: { ...block },
  deleteMovementWorkout: { ...block },

  movementWorkoutLevel: { ...block, data: [] },
  updateMovementWorkoutLevel: { ...block, data: [] },
  selectedMovementWorkoutLevel: { ...block, data: [] },
  addMovementWorkoutLevel: { ...block },
  deleteMovementWorkoutLevel: { ...block },

  nutritionLevel: { ...block, data: [] },
  selectedNutritionLevel: { ...block, data: [] },
  addNutritionLevel: { ...block },
  deleteNutritionLevel: { ...block },

  nutritionPlan: { ...block, data: [] },
  selectedNutritionPlan: { ...block, data: [] },
  addNutritionPlan: { ...block },
  deleteNutritionPlan: { ...block },

  nutritionHabit: { ...block, data: [] },
  updatedNutritionHabit: { ...block, data: [] },
  selectedNutritionHabit: { ...block, data: [] },
  addNutritionHabit: { ...block },
  deleteNutritionHabit: { ...block },

  morningMoment: { ...block, data: [] },
  updatedMorningMoment: { ...block, data: [] },
  selectedMorningMoment: { ...block, data: [] },
  addMorningMoment: { ...block },
  deleteMorningMoment: { ...block },
};

export const PlansReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_TYPE_REQUEST:
      return {
        ...state,
        getUser: { ...state.getUser, loading: true },
      };
    case GET_USER_TYPE_SUCCESS:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_USER_TYPE_ERROR:
      return {
        ...state,
        getUser: {
          ...state.getUser,
          loading: false,
          error: action.error,
        },
      };
    /*********************** MOVEMENT_WORKOUT ***********************/
    case GET_MOVEMENT_WORKOUT_REQUEST:
      return {
        ...state,
        movementWorkout: { ...state.movementWorkout, loading: true },
      };
    case GET_MOVEMENT_WORKOUT_SUCCESS:
      return {
        ...state,
        movementWorkout: {
          ...state.movementWorkout,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_MOVEMENT_WORKOUT_ERROR:
      return {
        ...state,
        movementWorkout: {
          ...state.movementWorkout,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_MOVEMENT_WORKOUT_REQUEST:
      return {
        ...state,
        movementWorkout: { ...state.movementWorkout, loading: true },
      };
    case UPDATE_MOVEMENT_WORKOUT_SUCCESS:
      const updateMovementDataIndex = state.movementWorkout.data.results.findIndex(
        (data) => data.id === action.data.id,
      );
      if (updateMovementDataIndex !== -1) state.movementWorkout.data.results[updateMovementDataIndex] = action.data;
      return {
        ...state,
        ...state.movementWorkout,
        updateMovementWorkout: {
          ...state.updateMovementWorkout,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case UPDATE_MOVEMENT_WORKOUT_ERROR:
      return {
        ...state,
        movementWorkout: {
          ...state.movementWorkout,
          loading: false,
          error: action.error,
        },
      };

    case GET_DETAIL_MOVEMENT_WORKOUT_REQUEST:
      return {
        ...state,
        selectedMovementWorkout: { ...state.selectedMovementWorkout, loading: true },
      };
    case GET_DETAIL_MOVEMENT_WORKOUT_SUCCESS:
      return {
        ...state,
        selectedMovementWorkout: {
          ...state.selectedMovementWorkout,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_DETAIL_MOVEMENT_WORKOUT_ERROR:
      return {
        ...state,
        selectedMovementWorkout: {
          ...state.selectedMovementWorkout,
          loading: false,
          error: action.error,
        },
      };

    case POST_MOVEMENT_WORKOUT_REQUEST:
      return {
        ...state,
        addMovementWorkout: { ...state.addMovementWorkout, loading: true },
      };
    case POST_MOVEMENT_WORKOUT_SUCCESS:
      const addNewWorkout = [...state.movementWorkout.data.results, action.data];
      return {
        ...state,
        addMovementWorkout: {
          ...state.addMovementWorkout,
          loading: false,
          success: true,
        },
        movementWorkout: {
          ...state.movementWorkout,
          data: {
            ...state.movementWorkout.data,
            results: addNewWorkout,
          },
        },
      };
    case POST_MOVEMENT_WORKOUT_ERROR:
      return {
        ...state,
        addMovementWorkout: {
          ...state.addMovementWorkout,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_MOVEMENT_WORKOUT_REQUEST:
      return {
        ...state,
        deleteMovementWorkout: { ...state.deleteMovementWorkout, loading: true },
      };
    case DELETE_MOVEMENT_WORKOUT_SUCCESS:
      const updateRec = [...state.movementWorkout.data.results].filter((data) => data.id !== action.id);
      return {
        ...state,
        deleteMovementWorkout: {
          ...state.deleteMovementWorkout,
          loading: false,
          success: true,
        },
        movementWorkout: {
          ...state.movementWorkout,
          data: {
            ...state.movementWorkout.data,
            results: updateRec,
          },
        },
      };
    case DELETE_MOVEMENT_WORKOUT_ERROR:
      return {
        ...state,
        deleteMovementWorkout: {
          ...state.deleteMovementWorkout,
          loading: false,
          error: action.error,
        },
      };
    /*********************** MOVEMENT_WORKOUT_END ***********************/

    /************************ MOVEMENT_LEVEL ************************/
    case GET_MOVEMENT_WORKOUT_LEVEL_REQUEST:
      return {
        ...state,
        movementWorkoutLevel: { ...state.movementWorkoutLevel, loading: true },
      };
    case GET_MOVEMENT_WORKOUT_LEVEL_SUCCESS:
      return {
        ...state,
        movementWorkoutLevel: {
          ...state.movementWorkoutLevel,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_MOVEMENT_WORKOUT_LEVEL_ERROR:
      return {
        ...state,
        movementWorkoutLevel: {
          ...state.movementWorkoutLevel,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_MOVEMENT_WORKOUT_LEVEL_REQUEST:
      return {
        ...state,
        movementWorkoutLevel: { ...state.movementWorkoutLevel, loading: true },
      };
    case UPDATE_MOVEMENT_WORKOUT_LEVEL_SUCCESS:
      const updateDataIndex = state.movementWorkoutLevel.data.results.findIndex((data) => data.id === action.data.id);
      if (updateDataIndex !== -1) state.movementWorkoutLevel.data.results[updateDataIndex] = action.data;
      return {
        ...state,
        ...state.movementWorkoutLevel,
        updateMovementWorkoutLevel: {
          ...state.updateMovementWorkoutLevel,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case UPDATE_MOVEMENT_WORKOUT_LEVEL_ERROR:
      return {
        ...state,
        movementWorkoutLevel: {
          ...state.movementWorkoutLevel,
          loading: false,
          error: action.error,
        },
      };

    case GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_REQUEST:
      return {
        ...state,
        selectedMovementWorkoutLevel: { ...state.selectedMovementWorkoutLevel, loading: true },
      };
    case GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_SUCCESS:
      return {
        ...state,
        selectedMovementWorkoutLevel: {
          ...state.selectedMovementWorkoutLevel,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_DETAIL_MOVEMENT_WORKOUT_LEVEL_ERROR:
      return {
        ...state,
        selectedMovementWorkoutLevel: {
          ...state.selectedMovementWorkoutLevel,
          loading: false,
          error: action.error,
        },
      };

    case POST_MOVEMENT_WORKOUT_LEVEL_REQUEST:
      return {
        ...state,
        addMovementWorkoutLevel: { ...state.addMovementWorkoutLevel, loading: true },
      };
    case POST_MOVEMENT_WORKOUT_LEVEL_SUCCESS:
      const newData = [...state.movementWorkoutLevel.data.results, action.data];
      return {
        ...state,
        addMovementWorkoutLevel: {
          ...state.addMovementWorkoutLevel,
          loading: false,
          success: true,
        },
        movementWorkoutLevel: {
          ...state.movementWorkoutLevel,
          data: { ...state.movementWorkoutLevel.data, results: newData },
        },
      };
    case POST_MOVEMENT_WORKOUT_LEVEL_ERROR:
      return {
        ...state,
        addMovementWorkoutLevel: {
          ...state.addMovementWorkoutLevel,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_MOVEMENT_WORKOUT_LEVEL_REQUEST:
      return {
        ...state,
        deleteMovementWorkoutLevel: { ...state.deleteMovementWorkoutLevel, loading: true },
      };
    case DELETE_MOVEMENT_WORKOUT_LEVEL_SUCCESS:
      const updateData = state.movementWorkoutLevel.data.results.filter((data) => data.id !== action.id);
      return {
        ...state,
        deleteMovementWorkoutLevel: {
          ...state.deleteMovementWorkoutLevel,
          loading: false,
          success: true,
        },
        movementWorkoutLevel: {
          ...state.movementWorkoutLevel,
          data: {
            ...state.movementWorkoutLevel.data,
            count: state.movementWorkoutLevel.data.count++,
            results: updateData,
          },
        },
      };
    case DELETE_MOVEMENT_WORKOUT_LEVEL_ERROR:
      return {
        ...state,
        deleteMovementWorkoutLevel: {
          ...state.deleteMovementWorkoutLevel,
          loading: false,
          error: action.error,
        },
      };

    case GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST:
      return {
        ...state,
        movementExerciseLibraryCategory: { ...state.movementExerciseLibraryCategory, loading: true },
      };
    case GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS:
      return {
        ...state,
        movementExerciseLibraryCategory: {
          ...state.movementExerciseLibraryCategory,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR:
      return {
        ...state,
        movementExerciseLibraryCategory: {
          ...state.movementExerciseLibraryCategory,
          loading: false,
          error: action.error,
        },
      };

    case ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_REQUEST:
      return {
        ...state,
        addMovementExerciseLibraryCategory: { ...state.addMovementExerciseLibraryCategory, loading: true },
      };
    case ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_SUCCESS:
      const newDataCat = [...state.addMovementExerciseLibraryCategory.data.results, action.data];
      return {
        ...state,
        addMovementExerciseLibraryCategory: {
          ...state.addMovementExerciseLibraryCategory,
          loading: false,
          success: true,
        },
        movementExerciseLibraryCategory: {
          ...state.addMovementExerciseLibraryCategory,
          data: { ...state.movementWorkoutLevel.data, results: newDataCat },
        },
      };
    case ADD_MOVEMENT_EXERCISE_LIBRARY_CATEGORY_ERROR:
      return {
        ...state,
        addMovementExerciseLibraryCategory: {
          ...state.addMovementExerciseLibraryCategory,
          loading: false,
          error: action.error,
        },
      };

    /*  User Corelation (Choose question option) */
    case GET_USER_CORELATION_REQUEST:
      return {
        ...state,
        getUserCorelation: {
          ...state.getUserCorelation,
          loading: true,
        },
      };
    case GET_USER_CORELATION_SUCCESS:
      return {
        ...state,
        getUserCorelation: {
          ...state.getUserCorelation,
          loading: false,
          success: true,
          error: '',
          data: action.data,
        },
      };
    case GET_USER_CORELATION_ERROR:
      return {
        ...state,
        getUserCorelation: {
          ...state.getUserCorelation,
          loading: false,
          error: action.error,
        },
      };

    case ADD_NEW_USER_CORELATION_REQUEST:
      return {
        ...state,
        updateUserCorelation: {
          ...state.updateUserCorelation,
          loading: true,
        },
      };
    case ADD_NEW_USER_CORELATION_SUCCESS:
      return {
        ...state,
        updateUserCorelation: {
          ...state.updateUserCorelation,
          loading: false,
          success: true,
          error: '',
        },
      };
    case ADD_NEW_USER_CORELATION_ERROR:
      return {
        ...state,
        updateUserCorelation: {
          ...state.updateUserCorelation,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_USER_CORELATION_REQUEST:
      return {
        ...state,
        deleteUserCorelation: {
          ...state.deleteUserCorelation,
          loading: true,
        },
      };
    case DELETE_USER_CORELATION_SUCCESS:
      return {
        ...state,
        deleteUserCorelation: {
          ...state.deleteUserCorelation,
          loading: false,
          success: true,
          error: '',
        },
      };
    case DELETE_USER_CORELATION_ERROR:
      return {
        ...state,
        deleteUserCorelation: {
          ...state.deleteUserCorelation,
          loading: false,
          error: action.error,
        },
      };

    case CREATE_USER_CORELATION_REQUEST:
      return {
        ...state,
        createUserCorelation: {
          ...state.createUserCorelation,
          loading: true,
        },
      };
    case CREATE_USER_CORELATION_SUCCESS:
      const addNewData = [...state.getUserCorelation.data.results, action.data];
      return {
        ...state,
        createUserCorelation: {
          ...state.createUserCorelation,
          loading: false,
          success: true,
          error: '',
        },
        getUserCorelation: {
          ...state.getUserCorelation,
          data: { ...state.getUserCorelation.data, results: addNewData },
        },
      };
    case CREATE_USER_CORELATION_ERROR:
      return {
        ...state,
        createUserCorelation: {
          ...state.createUserCorelation,
          loading: false,
          error: action.error,
        },
      };
    /************************ MOVEMENT_LEVEL_END ************************/

    /*********************** MOVEMENT_WORKOUT_GOAL***********************/
    case GET_MOVEMENT_WORKOUT_GOAL_REQUEST:
      return {
        ...state,
        movementWorkoutGoal: { ...state.movementWorkoutGoal, loading: true },
      };
    case GET_MOVEMENT_WORKOUT_GOAL_SUCCESS:
      return {
        ...state,
        movementWorkoutGoal: {
          ...state.movementWorkoutGoal,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_MOVEMENT_WORKOUT_GOAL_ERROR:
      return {
        ...state,
        movementWorkoutGoal: {
          ...state.movementWorkoutGoal,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_MOVEMENT_WORKOUT_GOAL_REQUEST:
      return {
        ...state,
        movementWorkoutGoal: { ...state.movementWorkoutGoal, loading: true },
      };
    case UPDATE_MOVEMENT_WORKOUT_GOAL_SUCCESS:
      const updateMovementGoalDataIndex = state.movementWorkoutGoal.data.results.findIndex(
        (data) => data.id === action.data.id,
      );
      if (updateMovementGoalDataIndex !== -1)
        state.movementWorkoutGoal.data.results[updateMovementGoalDataIndex] = action.data;
      return {
        ...state,
        ...state.movementWorkoutGoal,
        updateMovementWorkoutGoal: {
          ...state.updateMovementWorkoutGoal,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case UPDATE_MOVEMENT_WORKOUT_GOAL_ERROR:
      return {
        ...state,
        movementWorkoutGoal: {
          ...state.movementWorkoutGoal,
          loading: false,
          error: action.error,
        },
      };

    case POST_MOVEMENT_WORKOUT_GOAL_REQUEST:
      return {
        ...state,
        addMovementWorkoutGoal: { ...state.addMovementWorkoutGoal, loading: true },
      };
    case POST_MOVEMENT_WORKOUT_GOAL_SUCCESS:
      const addNewWorkoutGoal = [...state.movementWorkout.data.results, action.data];
      return {
        ...state,
        addMovementWorkoutGoal: {
          ...state.addMovementWorkoutGoal,
          loading: false,
          success: true,
        },
        movementWorkoutGoal: {
          ...state.movementWorkoutGoal,
          data: {
            ...state.movementWorkoutGoal.data,
            results: addNewWorkoutGoal,
          },
        },
      };
    case POST_MOVEMENT_WORKOUT_GOAL_ERROR:
      return {
        ...state,
        addMovementWorkoutGoal: {
          ...state.addMovementWorkoutGoal,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_MOVEMENT_WORKOUT_GOAL_REQUEST:
      return {
        ...state,
        deleteMovementWorkoutGoal: { ...state.deleteMovementWorkoutGoal, loading: true },
      };
    case DELETE_MOVEMENT_WORKOUT_GOAL_SUCCESS:
      const updateRecGoal = [...state.movementWorkoutGoal.data.results].filter((data) => data.id !== action.id);
      return {
        ...state,
        deleteMovementWorkoutGoal: {
          ...state.deleteMovementWorkoutGoal,
          loading: false,
          success: true,
        },
        movementWorkoutGoal: {
          ...state.movementWorkoutGoal,
          data: {
            ...state.movementWorkoutGoal.data,
            results: updateRecGoal,
          },
        },
      };
    case DELETE_MOVEMENT_WORKOUT_GOAL_ERROR:
      return {
        ...state,
        deleteMovementWorkoutGoal: {
          ...state.deleteMovementWorkoutGoal,
          loading: false,
          error: action.error,
        },
      };
    /*********************** MOVEMENT_WORKOUT_GOAL_END ***********************/

    //******************** NUTRITION LEVEL ***************************

    case GET_NUTRITION_LEVEL_REQUEST:
      return {
        ...state,
        nutritionLevel: { ...state.nutritionLevel, loading: true },
      };
    case GET_NUTRITION_LEVEL_SUCCESS:
      return {
        ...state,
        nutritionLevel: {
          ...state.nutritionLevel,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_NUTRITION_LEVEL_ERROR:
      return {
        ...state,
        nutritionLevel: {
          ...state.nutritionLevel,
          loading: false,
          error: action.error,
        },
      };

    case GET_DETAIL_NUTRITION_LEVEL_REQUEST:
      return {
        ...state,
        selectedNutritionLevel: { ...state.selectedNutritionLevel, loading: true },
      };
    case GET_DETAIL_NUTRITION_LEVEL_SUCCESS:
      return {
        ...state,
        selectedNutritionLevel: {
          ...state.selectedNutritionLevel,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_DETAIL_NUTRITION_LEVEL_ERROR:
      return {
        ...state,
        selectedNutritionLevel: {
          ...state.selectedNutritionLevel,
          loading: false,
          error: action.error,
        },
      };

    case POST_NUTRITION_LEVEL_REQUEST:
      return {
        ...state,
        addNutritionLevel: { ...state.addNutritionLevel, loading: true },
      };
    case POST_NUTRITION_LEVEL_SUCCESS:
      const newLevelData = [...state.nutritionLevel.data.results, action.data];
      return {
        ...state,
        addNutritionLevel: {
          ...state.addNutritionLevel,
          loading: false,
          success: true,
        },
        nutritionLevel: {
          ...state.nutritionLevel,
          data: { ...state.nutritionLevel.data, results: newLevelData },
        },
      };
    case POST_NUTRITION_LEVEL_ERROR:
      return {
        ...state,
        addNutritionLevel: {
          ...state.addNutritionLevel,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_NUTRITION_LEVEL_REQUEST:
      return {
        ...state,
        deleteNutritionLevel: { ...state.deleteNutritionLevel, loading: true },
      };
    case DELETE_NUTRITION_LEVEL_SUCCESS:
      const updateLevelData = state.nutritionLevel.data.results.filter((data) => data.id !== action.id);
      return {
        ...state,
        deleteNutritionLevel: {
          ...state.deleteNutritionLevel,
          loading: false,
          success: true,
        },
        nutritionLevel: {
          ...state.nutritionLevel,
          data: {
            ...state.nutritionLevel.data,
            count: state.nutritionLevel.data.count--,
            results: updateLevelData,
          },
        },
        nutritionHabit: {
          data: {
            ...state.nutritionLevel.data,
            count: state.nutritionLevel.data.count++,
            results: updateLevelData,
          },
        },
      };
    case DELETE_NUTRITION_LEVEL_ERROR:
      return {
        ...state,
        deleteNutritionLevel: {
          ...state.deleteNutritionLevel,
          loading: false,
          error: action.error,
        },
      };

    /* Nutrion Plan Start*/

    case GET_NUTRITION_PLAN_REQUEST:
      return {
        ...state,
        nutritionPlan: { ...state.nutritionPlan, loading: true },
      };
    case GET_NUTRITION_PLAN_SUCCESS:
      return {
        ...state,
        nutritionPlan: {
          ...state.nutritionPlan,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_NUTRITION_PLAN_ERROR:
      return {
        ...state,
        nutritionPlan: {
          ...state.nutritionPlan,
          loading: false,
          error: action.error,
        },
      };

    case GET_DETAIL_NUTRITION_PLAN_REQUEST:
      return {
        ...state,
        selectedNutritionPlan: { ...state.selectedNutritionPlan, loading: true },
      };
    case GET_DETAIL_NUTRITION_PLAN_SUCCESS:
      return {
        ...state,
        selectedNutritionPlan: {
          ...state.selectedNutritionPlan,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_DETAIL_NUTRITION_PLAN_ERROR:
      return {
        ...state,
        selectedNutritionPlan: {
          ...state.selectedNutritionPlan,
          loading: false,
          error: action.error,
        },
      };

    case POST_NUTRITION_PLAN_REQUEST:
      return {
        ...state,
        addNutritionPlan: { ...state.addNutritionPlan, loading: true },
      };
    case POST_NUTRITION_PLAN_SUCCESS:
      const newPlanData = [...state.nutritionPlan.data.results, action.data];
      return {
        ...state,
        addNutritionPlan: {
          ...state.addNutritionPlan,
          loading: false,
          success: true,
        },
        nutritionPlan: {
          ...state.nutritionPlan,
          data: { ...state.nutritionPlan.data, results: newPlanData },
        },
      };
    case POST_NUTRITION_PLAN_ERROR:
      return {
        ...state,
        addNutritionPlan: {
          ...state.addNutritionPlan,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_NUTRITION_PLAN_REQUEST:
      return {
        ...state,
        deleteNutritionPlan: { ...state.deleteNutritionPlan, loading: true },
      };
    case DELETE_NUTRITION_PLAN_SUCCESS:
      const updatePlanData = state.nutritionPlan.data.results.filter((data) => data.id !== action.id);
      return {
        ...state,
        deleteNutritionPlan: {
          ...state.deleteNutritionPlan,
          loading: false,
          success: true,
        },
        nutritionPlan: {
          ...state.nutritionPlan,
          data: {
            ...state.nutritionPlan.data,
            count: state.nutritionPlan.data.count++,
            results: updatePlanData,
          },
        },
      };
    case DELETE_NUTRITION_PLAN_ERROR:
      return {
        ...state,
        deleteNutritionPlan: {
          ...state.deleteNutritionPlan,
          loading: false,
          error: action.error,
        },
      };

    /* Nutrion Plan end*/

    /* Nutrion Habit Start*/

    case GET_NUTRITION_HABIT_REQUEST:
      return {
        ...state,
        nutritionHabit: { ...state.nutritionHabit, loading: true },
      };
    case GET_NUTRITION_HABIT_SUCCESS:
      return {
        ...state,
        nutritionHabit: {
          ...state.nutritionHabit,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_NUTRITION_HABIT_ERROR:
      return {
        ...state,
        nutritionHabit: {
          ...state.nutritionHabit,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_NUTRITION_HABIT_REQUEST:
      return {
        ...state,
        nutritionHabit: { ...state.nutritionHabit, loading: true },
      };
    case UPDATE_NUTRITION_HABIT_SUCCESS:
      const updateNutritionDataIndex = state.nutritionHabit.data.results.findIndex(
        (data) => data.id === action.data.id,
      );
      if (updateNutritionDataIndex !== -1) state.nutritionHabit.data.results[updateNutritionDataIndex] = action.data;
      return {
        ...state,
        ...state.nutritionHabit,
        updatedNutritionHabit: {
          ...state.updatedNutritionHabit,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case UPDATE_NUTRITION_HABIT_ERROR:
      return {
        ...state,
        nutritionHabit: {
          ...state.nutritionHabit,
          loading: false,
          error: action.error,
        },
      };

    case GET_DETAIL_NUTRITION_HABIT_REQUEST:
      return {
        ...state,
        selectedNutritionHabit: { ...state.selectedNutritionHabit, loading: true },
      };
    case GET_DETAIL_NUTRITION_HABIT_SUCCESS:
      return {
        ...state,
        selectedNutritionHabit: {
          ...state.selectedNutritionHabit,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_DETAIL_NUTRITION_HABIT_ERROR:
      return {
        ...state,
        selectedNutritionHabit: {
          ...state.selectedNutritionHabit,
          loading: false,
          error: action.error,
        },
      };

    case POST_NUTRITION_HABIT_REQUEST:
      return {
        ...state,
        addNutritionHabit: { ...state.addNutritionHabit, loading: true },
      };
    case POST_NUTRITION_HABIT_SUCCESS:
      const newHabitData = [...state.nutritionHabit.data.results, action.data];
      return {
        ...state,
        addNutritionHabit: {
          ...state.addNutritionHabit,
          loading: false,
          success: true,
        },
        nutritionHabit: {
          ...state.nutritionHabit,
          data: { ...state.nutritionHabit.data, results: newHabitData },
        },
      };
    case POST_NUTRITION_HABIT_ERROR:
      return {
        ...state,
        addNutritionHabit: {
          ...state.addNutritionHabit,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_NUTRITION_HABIT_REQUEST:
      return {
        ...state,
        deleteNutritionHabit: { ...state.deleteNutritionHabit, loading: true },
      };
    case DELETE_NUTRITION_HABIT_SUCCESS:
      const updateHabitData = state.nutritionHabit.data.results.filter((data) => data.id !== action.id);
      return {
        ...state,
        deleteNutritionHabit: {
          ...state.deleteNutritionHabit,
          loading: false,
          success: true,
        },
        nutritionHabit: {
          ...state.nutritionHabit,
          data: {
            ...state.nutritionHabit.data,
            count: state.nutritionHabit.data.count++,
            results: updateHabitData,
          },
        },
      };
    case DELETE_NUTRITION_HABIT_ERROR:
      return {
        ...state,
        deleteNutritionHabit: {
          ...state.deleteNutritionHabit,
          loading: false,
          error: action.error,
        },
      };

    /* Nutrion Habit end*/

    /* Morning Moment Start*/

    case GET_MORNING_MOMENT_REQUEST:
      return {
        ...state,
        morningMoment: { ...state.morningMoment, loading: true },
      };
    case GET_MORNING_MOMENT_SUCCESS:
      return {
        ...state,
        morningMoment: {
          ...state.morningMoment,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_MORNING_MOMENT_ERROR:
      return {
        ...state,
        morningMoment: {
          ...state.morningMoment,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_MORNING_MOMENT_REQUEST:
      return {
        ...state,
        morningMoment: { ...state.morningMoment, loading: true },
      };
    case UPDATE_MORNING_MOMENT_SUCCESS:
      const updateMomentDataIndex = state.morningMoment.data.results.findIndex((data) => data.id === action.data.id);
      if (updateMomentDataIndex !== -1) state.morningMoment.data.results[updateMomentDataIndex] = action.data;
      return {
        ...state,
        ...state.morningMoment,
        updatedMorningMoment: {
          ...state.updatedMorningMoment,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case UPDATE_MORNING_MOMENT_ERROR:
      return {
        ...state,
        morningMoment: {
          ...state.morningMoment,
          loading: false,
          error: action.error,
        },
      };

    case GET_DETAIL_MORNING_MOMENT_REQUEST:
      return {
        ...state,
        selectedMorningMoment: { ...state.selectedMorningMoment, loading: true },
      };
    case GET_DETAIL_MORNING_MOMENT_SUCCESS:
      return {
        ...state,
        selectedMorningMoment: {
          ...state.selectedMorningMoment,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_DETAIL_MORNING_MOMENT_ERROR:
      return {
        ...state,
        selectedMorningMoment: {
          ...state.selectedMorningMoment,
          loading: false,
          error: action.error,
        },
      };

    case POST_MORNING_MOMENT_REQUEST:
      return {
        ...state,
        addMorningMoment: { ...state.addMorningMoment, loading: true },
      };
    case POST_MORNING_MOMENT_SUCCESS:
      const newMorningMomentData = [...state.morningMoment.data.results, action.data];
      return {
        ...state,
        addMorningMoment: {
          ...state.addMorningMoment,
          loading: false,
          success: true,
        },
        morningMoment: {
          ...state.morningMoment,
          data: { ...state.morningMoment.data, results: newMorningMomentData },
        },
      };
    case POST_MORNING_MOMENT_ERROR:
      return {
        ...state,
        addMorningMoment: {
          ...state.addMorningMoment,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_MORNING_MOMENT_REQUEST:
      return {
        ...state,
        deleteMorningMoment: { ...state.deleteMorningMoment, loading: true },
      };
    case DELETE_MORNING_MOMENT_SUCCESS:
      const updatenewMorningMomentData = state.morningMoment.data.results.filter((data) => data.id !== action.id);
      return {
        ...state,
        deleteMorningMoment: {
          ...state.deleteMorningMoment,
          loading: false,
          success: true,
        },
        morningMoment: {
          ...state.morningMoment,
          data: {
            ...state.morningMoment.data,
            count: state.morningMoment.data.count++,
            results: updatenewMorningMomentData,
          },
        },
      };
    case DELETE_MORNING_MOMENT_ERROR:
      return {
        ...state,
        deleteMorningMoment: {
          ...state.deleteMorningMoment,
          loading: false,
          error: action.error,
        },
      };

    /* Morning Moment end*/

    //reset block with flag and data
    case RESET_BLOCK_PLANS:
      return {
        ...state,
        [action.payload.blockType]: {
          ...state[action.payload.blockType],
          ...initialState[action.payload.blockType],
        },
      };

    case RESET_FLAGS_PLANS:
      return {
        ...state,
        [action.payload.blockType]: {
          ...state[action.payload.blockType],
          ...block,
        },
      };

    default:
      return state;
  }
};
