import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DefaultLightBox from '../../UI/DefaultLightBox';
import filter from '../../../assets/icons/filter-green.svg';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';

import Form from 'react-bootstrap/Form';

import Search from '../../../assets/icons/search.svg';
import UserChatList from './UserChatList';

import {
  createTheme,
  makeStyles,
  ThemeProvider
} from '@material-ui/core/styles';
import './userList.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#62A8A4',
      contrastText: '#fff'
    }
  }
});

const UserList = ({ data = [] }) => {
  const classes = useStyles();
  const { getUsers } = useSelector(({ users }) => users);

  const users = (getUsers && getUsers.data) ? getUsers.data.filter(user => data.find(id => id === user.user_id)) : [];

  const [ search, setSearch ] = useState('');

  const filterBySearch = arrayOfUsers => {
    return arrayOfUsers.filter(({ first_name, last_name, user_type }) => {
      let searchValue = search.toLowerCase();

      return (
        `${first_name} ${last_name}`.toLowerCase().search(searchValue) > -1 ||
        user_type.toLowerCase().search(searchValue) > -1
      );
    });
  }

  const onSearchChangeHandle = event => {
    setSearch(event.target.value);
  }

  return (
    <ThemeProvider theme={theme}>
      <DefaultLightBox className="userMessageListWrapper">
        <Row className={`p-3 relative align-item-center pb-2`}>
          <Col>
            <span className="text-uppercase">
              Users in group chat ({users.length})
            </span>
          </Col>
          <Col className="text-end">
            <Button variant="outlined" className={classes.button}>
              <img src={filter} alt="filter" />
              <p className="searchBar-content">Filters</p>
              <Badge
                badgeContent={'0'}
                className={classes.spacing}
                color="primary"
              />
            </Button>
          </Col>
        </Row>
        <Row className="searchInputWrapper">
          <Col>
            <Form.Group className="p-2 inputBoxWrapper">
              <Form.Control
                type="search"
                placeholder="Search users"
                name="search"
                value={search}
                onChange={onSearchChangeHandle}
              />
              <Button className="p-0 iconAction">
                <img src={Search} alt="search" />
              </Button>
            </Form.Group>
          </Col>
        </Row>

        <div className="userList">
          <div className="userListWrapper">
            {filterBySearch(users).map(user => <UserChatList data={user} key={user.user_id} /> )}
          </div>
        </div>
      </DefaultLightBox>
    </ThemeProvider>
  );
};

const useStyles = makeStyles(theme => ({
  button: {
    color: '#62A8A4',
    textTransform: 'capitalize',
    width: '120px',
    height: '35px'
  },
  spacing: {
    margin: '0 10px'
  },
  MuiBadge: {
    backgroundColor: 'red'
  }
}));

export default UserList;
