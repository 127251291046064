import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

import './style.scss';

const Button = ({ text = '', type = 'cancel', htmlType = 'button', loading, ...rest }) => {
  return (
    <button {...rest} type={htmlType} className={`${type === 'cancel' ? 'cancelBtn' : 'saveBtn'} w-200`}>
      {text}
      {loading && <Spinner animation="border" className="spinner" />}
    </button>
  );
};
export default Button;
