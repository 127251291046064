import React from 'react';
import { Accordion, useAccordionButton } from 'react-bootstrap';

function CustomToggle({ children = '', eventKey = 0, setActiveKey = 0, activeKey = 0 }) {
  const decoratedOnClick = useAccordionButton(eventKey, (e) => {
    if (activeKey === eventKey) {
      setActiveKey('-1');
      return;
    }
    setActiveKey(eventKey);
  });
  return (
    <Accordion.Header onClick={decoratedOnClick} className={activeKey === eventKey && `off`}>
      {children}
    </Accordion.Header>
  );
}
export default CustomToggle;
