import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import NutritionPlansLevel from './NutritionPlansLevel';
import './nutritionPlans.scss';
const NutritionPlans = () => {
  return (
    <div className="plansWorkout">
      <Tabs defaultActiveKey="Level1" id="plansWorkout" className="">
        <Tab eventKey="Level1" title="Level 1">
          <NutritionPlansLevel />
        </Tab>
      </Tabs>
    </div>
  );
};
export default NutritionPlans;
