import React from 'react';
import InputWithSearch from '../../../../InputWithSearch/InputWithSearch';
import './plansUsersLevel.scss';
import DummyUser from '../../../../../assets/images/userDummy.png';
import UserBox from './UserBox';

const PlansUsersLevel = () => {
  const allUser = [
    { id: 1, img: DummyUser, name: 'Lorene Stokes' },
    { id: 2, img: DummyUser, name: 'Lowell Walker' },
    { id: 3, img: DummyUser, name: 'Tricia Pearson' },
    { id: 4, img: DummyUser, name: 'Pearl Pierce' },
    { id: 5, img: DummyUser, name: 'Lorene Stokes' },
    { id: 6, img: DummyUser, name: 'Lowell Walker' },
    { id: 7, img: DummyUser, name: 'Tricia Pearson' },
    { id: 8, img: DummyUser, name: 'Tricia Pearson' },
    { id: 9, img: DummyUser, name: 'Tricia Pearson' },
    { id: 10, img: DummyUser, name: 'Tricia Pearson' },
    { id: 11, img: DummyUser, name: 'Lowell Walker' },
    { id: 12, img: DummyUser, name: 'Lowell Walker' },
    { id: 13, img: DummyUser, name: 'Lowell Walker' },
    { id: 14, img: DummyUser, name: 'Lowell Walker' },
    { id: 16, img: DummyUser, name: 'Lowell Walker' },
    { id: 17, img: DummyUser, name: 'Lorene Stokes' },
    { id: 18, img: DummyUser, name: 'Lorene Stokes' },
    { id: 19, img: DummyUser, name: 'Lorene Stokes' },
    { id: 20, img: DummyUser, name: 'Lorene Stokes' },
    { id: 21, img: DummyUser, name: 'Lorene Stokes' },
  ];
  return (
    <div className="row bgWhite">
      <div className="col-12 pt-3">
        <InputWithSearch placeholder={'Users'} />
      </div>

      <div className="allUsersList">
        <div className="row py-3">
          {allUser.map((user) => (
            <UserBox user={user} key={user.id} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default PlansUsersLevel;
