import React, { useState, useEffect } from 'react';
import MoveIcon from '../../../../../assets/icons/MoveIcon';
import { Accordion } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CustomToggleExercises from '../../../../Exercises/ExercisesAccordionUi/CustomToggleExercises';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';

import './nutritionLevel.scss';

import NutritionCollapse from './NutritionCollapse';
import {
  addNutritionPlan,
  // deleteNutritionHabit,
  deleteNutritionPlan
} from '../../../../../redux/actions/PlansAction';

const NutritionLevel = ({
  selectedNutritionData,
  activeNutritionLevel,
  nutritionLevelId
}) => {
  const dispatch = useDispatch();
  const {
    nutritionPlan: { data: getNutritionPlanData },
    nutritionHabit
  } = useSelector(({ plan }) => plan);

  const [activeKey, setActiveKey] = useState('1');
  const [nutritionData, setNutritionData] = useState([]);

  const handleTime = () => {
    const structure = {
      timeBar: true,
      nutrition: false
    };
    setNutritionData([...nutritionData, structure]);
  };

  const handleWorkout = () => {
    const structure = {
      timeBar: false,
      nutrition: true
    };
    setNutritionData([...nutritionData, structure]);
    dispatch(
      addNutritionPlan({
        level: selectedNutritionData.id,
        name: `Nutrition Plan`,
        days: [1, 2],
        weeks: [1, 2]
      })
    );
  };

  useEffect(() => {
    if (getNutritionPlanData) {
      const { results = [] } = getNutritionPlanData;
      const filterData = results.filter(
        data => data.level == selectedNutritionData.id
      );
      setNutritionData(filterData);
    }
  }, [getNutritionPlanData, activeNutritionLevel]);

  const handleDeleteNutrionPlan = (data = {}) => () => {
    // debugger
    // if (data?.habits[0]) {
    //   const { id } = data.habits[0];
    //   dispatch(deleteNutritionHabit({ id }));
    // }
    dispatch(deleteNutritionPlan({ id: data.id }));
  };

  // console.log('data')
  // console.log(nutritionData)

  return (
    <div className="nutritionLevelWrapper">
      <div className="nutritionAccordion">
        <Accordion defaultActiveKey="0" activeKey={activeKey}>
          {nutritionData.map((data = {}, index) => (
            <div key={index}>
              {/*top level add workout screen*/}
              {/* {data.timeBar && (
                <DaysWeekBar
                  className="border-top"
                  days={timeBar.days}
                  week={timeBar.week}
                  month={timeBar.month}
                  setTimeBar={setTimeBar}
                  key={index}
                />
              )} */}
              <Card className={`border-0`}>
                <Card.Header className={`accordionWorkout`}>
                  <MoveIcon />

                  <CustomToggleExercises
                    setActiveKey={setActiveKey}
                    eventKey={index}
                    activeKey={activeKey}
                  >
                    &nbsp; {data.name}
                  </CustomToggleExercises>
                  <DropdownButton
                    title=""
                    drop={`start`}
                    className={`menuDots`}
                  >
                    <Dropdown.Item
                      onClick={handleDeleteNutrionPlan(
                        data
                      )}
                    >
                      Delete
                    </Dropdown.Item>
                  </DropdownButton>
                </Card.Header>

                <Accordion.Collapse eventKey={index}>
                  <>
                    <NutritionCollapse
                      selectedNutritionData={data}
                      nutritionLevelId={activeNutritionLevel.id}
                      habitId={data.id}
                    />
                  </>
                </Accordion.Collapse>
              </Card>
            </div>
          ))}
        </Accordion>

        <div className="px-3 py-2 borderBottom borderBottom--buttons">
          <Button className="whiteBtn" onClick={handleTime}>
            + Add time selection
          </Button>
          <Button className="whiteBtn ms-3" onClick={handleWorkout}>
            + Add plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NutritionLevel;
