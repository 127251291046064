import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import theme from './theme';
import configureStore, { history } from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyB9zFgZvCM1PRgcKBb31CuUkacxg9NLDUM",
  authDomain: "blaque-fitness.firebaseapp.com",
  databaseURL: "https://blaque-fitness-default-rtdb.firebaseio.com",
  projectId: "blaque-fitness",
  storageBucket: "blaque-fitness.appspot.com",
  messagingSenderId: "719293102387",
  appId: "1:719293102387:web:bebc8bbc3b6dfd8c722646",
  measurementId: "G-6LFSTXX855"
};

firebase.initializeApp(firebaseConfig)
firebase.firestore()

const initialState = {};

const store = configureStore(initialState);

const rrfConfig = {
  userProfile: 'users'
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <ThemeProvider theme={theme}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <App />
          </ThemeProvider>
        </ReactReduxFirebaseProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
