import {
  ADD_CATEGORY_REQUEST,
  EDIT_CATEGORY_REQUEST,
  DELETE_CATEGORY_REQUEST,

  GET_EXERCISES_REQUEST,

  ADD_EXERCISE_REQUEST,
  EDIT_EXERCISE_REQUEST,
  DELETE_EXERCISE_REQUEST,
} from '../reducers/ExercisesReducer';

export const addCategory = payload => ({ type: ADD_CATEGORY_REQUEST, payload });
export const editCategory = payload => ({ type: EDIT_CATEGORY_REQUEST, payload });
export const deleteCategory = payload => ({ type: DELETE_CATEGORY_REQUEST, payload });

export const getExercises = payload => ({ type: GET_EXERCISES_REQUEST, payload });

export const addExercise = payload => ({ type: ADD_EXERCISE_REQUEST, payload });
export const editExercise = payload => ({ type: EDIT_EXERCISE_REQUEST, payload });
export const removeExercise = payload => ({ type: DELETE_EXERCISE_REQUEST, payload });
