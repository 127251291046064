import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

import SendWrapper from './SendWrapper';
import ReceiveWrapper from './ReceiveWrapper';

import Form from 'react-bootstrap/Form';

import placeholder from '../../../assets/images/placeholder3.png';
import SendIcon from '../../../assets/icons/SendIcon';

import './chatMessage.scss';

const ChatMessage = ({ data, primaryUser, secondaryUser, search, onMessageSend }) => {
  const [ message, setMessage ] = useState('');

  const foundElements = useRef([]);
  const areaElement = useRef(null);
  let activeElement = useRef(0);

  useLayoutEffect(() => {
    if (areaElement.current)
      areaElement.current.scrollTop = areaElement.current.scrollHeight;
  }, [data]);

  useEffect(() => {
    if (search.length < 2)
      return;

    const results = Array.from(areaElement.current.getElementsByClassName('search-result'));
    foundElements.current = results.map(result => result.closest('.messageWrapper').offsetTop);

    activeElement.current = 0;
    scrollToFoundElement();
  }, [search]);

  const onChangeHandle = event => {
    setMessage(event.target.value);
  }

  const onSubmitHandle = event => {
    event.preventDefault();

    onMessageSend(message);
    setMessage('');
  }

  const scrollToFoundElement = () => {
    areaElement.current.scrollTop = foundElements.current[activeElement.current];
  }

  return (
    <>
      <div className="chat-wrapper" ref={areaElement}>
        <div className="chatMessageWrapper">
          {data.map(message => (
            message.sender == secondaryUser.user_id
            ? <ReceiveWrapper message={message} sender={secondaryUser} searchQuery={search.length > 1 ? search : ''} key={message.id} />
            : <SendWrapper message={message} sender={primaryUser} searchQuery={search.length > 1 ? search : ''} key={message.id} />
          ))}
        </div>
      </div>
      <Form className="sendMessageWrapper" onSubmit={onSubmitHandle}>
        <div className="inputWrapper">
          <span>Message</span>
          <Form.Control as="textarea" style={{ height: '70px' }} value={message} onChange={onChangeHandle} required />
        </div>
        <button className="me-3 mt-2 pointer">
          <SendIcon />
        </button>
      </Form>
    </>
  );
};

export default ChatMessage;
