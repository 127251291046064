import React, { useEffect, useRef, useState } from 'react';
import MoveIcon from '../../../../../assets/icons/MoveIcon';
import './exerciseTable.scss';
import Form from 'react-bootstrap/Form';
import checkbox from '../../../../../assets/icons/checkbox.svg';
import checkboxSelect from '../../../../../assets/icons/checkbox-select.svg';
const ExerciseTable = (props) => {
  const { group = false, className: classes, data = {}, handleChecked, groupId = null, handleUpdateName } = props;
  const { isChecked, id, title } = data;
  const [updateToggle, setUpdateToggle] = useState(false);
  const [exerciseTitle, setExerciseTitle] = useState(title);
  const ref = useRef();
  const handleOpenInput = () => {
    setUpdateToggle(true);
  };
  const handleBlur = () => {
    setUpdateToggle(false);
  };
  const handleChange = (e) => {
    setExerciseTitle(ref.current.value);
  };
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleBlur();
      handleUpdateName(id, exerciseTitle, groupId);
    }
    if (e.keyCode === 27) {
      handleBlur();
      setExerciseTitle(title);
    }
  };
  useEffect(() => {
    if (updateToggle) ref.current.focus();
  }, [updateToggle]);

  return (
    <>
      <tr className={`exerciseRow borderBottom ${classes}`}>
        <td>
          <span className="exerciseName">
            <MoveIcon />
            {!group && (
              <Form.Check
                type="checkbox"
                id={`group-${Math.random(10)}`}
                className="p-0"
                label={<img src={isChecked ? checkboxSelect : checkbox} />}
                onChange={handleChecked(id, groupId)}
                checked={isChecked}
              />
            )}
            {group && <span className="ps-2">{`${exerciseTitle} `}</span>}
            {updateToggle ? (
              <Form.Control
                type="text"
                ref={ref}
                value={exerciseTitle}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
              />
            ) : (
              <span onClick={handleOpenInput}>{exerciseTitle}</span>
            )}
          </span>
        </td>
        <td>
          <Form.Select size="sm" className="dropdown">
            <option>Reps</option>
            <option>Time </option>
            <option>Speed </option>
            <option>Incline</option>
            <option>Distance</option>
          </Form.Select>
        </td>
        <td>
          <Form.Select size="sm" className="dropdown">
            <option>As many</option>
            <option>30 seconds</option>
            <option>45 seconds</option>
            <option>55 seconds</option>
          </Form.Select>
        </td>
        <td>
          <Form.Select size="sm" className="dropdown">
            <option>1 round</option>
            <option>2 round</option>
            <option>3 round</option>
            <option>4 round</option>
          </Form.Select>
        </td>
        <td className="d-flex align-items-center">
          <Form.Select size="sm" className="dropdown">
            <option>20 sec</option>
            <option>30 sec</option>
            <option>40 sec</option>
            <option>50 sec</option>
            <option>60 sec</option>
          </Form.Select>
          <span className="smallText">add goal</span>
        </td>
      </tr>
    </>
  );
};
export default ExerciseTable;
