import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  color: {
    fontSize: '11px',
    padding: '0 8px',
    fontWeight: 'bold',
    color: theme.palette.link.main,
    lineHeight: '35px',
  },
  link: {
    fontSize: '11px',
    padding: '0 8px',
    fontWeight: 'bold',
    color: '#62A8A4',
    lineHeight: '35px',
  },
  divider: {
    fontSize: '11px',
  },
}));

export default useStyles;
