import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { updateNutritionHabit } from '../../../../../redux/actions/PlansAction';
import './nutritionCollapse.scss';
import NutritionTableRow from './NutritionTableRow';

const NutritionCollapse = ({
  selectedNutritionData: { habits = [] },
  selectedNutritionData,
  habitId
}) => {
  const dispatch = useDispatch();

  const {
    nutritionHabit: { data: getNutritionPlanData }
  } = useSelector(({ plan }) => plan);

  const [nutritionHabitData, setNutritionHabitData] = useState({});

  const data = [
    { id: 1, title: 'Eat at least two vegetables today' }
    // { id: 2, title: 'Eat at least two fruits today' },
    // { id: 3, title: 'Eat at least two vegetables today' },
  ];

  const onChangeHandler = ({ target: { name, value } }) => {
    setNutritionHabitData({ ...nutritionHabitData, [name]: value });
  };

  const handleUpdateHabit = type => () => {
    const dataKey = Object.keys(type)[0];
    const filterData = getNutritionPlanData?.results?.find(
      i => i.plan === habitId
    );

    if (filterData && filterData[dataKey] !== type[dataKey])
      dispatch(
        updateNutritionHabit({
          id: nutritionHabitData?.id,
          payload: type
        })
      );
  };

  useEffect(() => {
    if (getNutritionPlanData) {
      setNutritionHabitData(
        getNutritionPlanData?.results?.find(i => i.plan === habitId)
      );
    }
  }, [getNutritionPlanData, habitId]);

  return (
    <>
      <div className="nutritionFormWrapper">
        <Form.Group className="mb-3" controlId="habit">
          <Form.Label>Habit text</Form.Label>
          <Form.Control
            type="text"
            name="text"
            onBlur={handleUpdateHabit({
              text: nutritionHabitData?.text
            })}
            onChange={onChangeHandler}
            value={nutritionHabitData?.text || ''}
          />
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="video">
          <Form.Label>Attach video</Form.Label>
          <Form.Control
            type="text"
            name="video"
            onChange={onChangeHandler}
            onBlur={handleUpdateHabit({
              video: nutritionHabitData?.video
            })}
            value={nutritionHabitData?.video || ''}
          />
        </Form.Group> */}

        <Form.Group controlId="habit">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="notes"
            onChange={onChangeHandler}
            onBlur={handleUpdateHabit({
              notes: nutritionHabitData?.text
            })}
            value={nutritionHabitData?.notes || ''}
            style={{ height: '60px' }}
          />
        </Form.Group>
      </div>
      <div className="nutritionTableWrapper borderBottom">
        <table className="w-100">
          <tr className="workoutTableTitle">
            <td>Habit</td>
            <td>Type</td>
            <td>Target</td>
            <td>Rate</td>
          </tr>
          <NutritionTableRow
            nutritionHabitData={nutritionHabitData}
            handleUpdateHabit={handleUpdateHabit}
            onBlur={handleUpdateHabit({
              target: nutritionHabitData?.target || 'Habit'
            })}
            onChangeHandler={onChangeHandler}
            title={nutritionHabitData?.text}
          />
          {/* {data && data.map((data, index) => <NutritionTableRow key={index} title={data.title} />)} */}
        </table>
      </div>
    </>
  );
};

export default NutritionCollapse;
