import React, { useEffect } from 'react';
import { Route, Switch, Redirect, withRouter, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { isLoggedIn } from './utils/utility';
import Routes from './Routes';
import Container from 'react-bootstrap/Container';
import Navigation from './components/Navbar/Navigation';
import DefaultAPI from './pages/DefaultAPI';

const renderRoutes = () => {
  const renderRoute = (routerProps, Component, props, isPrivate = false, name) => {
    if (Component) {
      const componentProps = {
        ...routerProps,
        ...props,
      };
      if (name === 'NotFoundPage') {
        return isLoggedIn() ? <Redirect to="/dashboard" /> : <Redirect to="/login" />;
      }
      if (isPrivate) {
        return isLoggedIn() ? (
          <>
            <Navigation />
            <Component {...componentProps} />
          </>
        ) : (
          <Redirect to="/login" />
        );
      }
      return isLoggedIn() ? <Redirect to="/dashboard" /> : <Component {...componentProps} />; // eslint-disable-line
    }
    return null;
  };

  return Routes.map((route) => (
    <Route
      key={route.name}
      exact={route.exact}
      path={route.path}
      render={(routerProps) => renderRoute(routerProps, route.component, route.props, route.isPrivate, route.name)}
    />
  ));
};

const Router = () => <Switch>{renderRoutes()}</Switch>;

const App = () => {
  const history = useHistory();

  const {
    logout: { success },
  } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (success) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <div className="App">
      <section className="App-content">
        <Container>
          <DefaultAPI />
          <Router />
        </Container>
      </section>
    </div>
  );
};

export default withRouter(App);
