import React from 'react';
import './breadcrumb.css';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link, useHistory } from 'react-router-dom';

import useStyles from './styles';

export default function Breadcrumb(props) {
  const classes = useStyles();
  const history = useHistory();
  const { className, setSelectedUser = () => {},route='Dashboard' } = props;
  const handleClick = (nav, event) => {
    event.preventDefault();
    if (nav === 'Users') {
      setSelectedUser('');
      history.push(`/users`);
    }
  };
  return (
    <div className={`breadcrumb-container mt-3 ${className ? className : ''}`}>
      <div>
        <Breadcrumbs aria-label="breadcrumb" className={classes.divider}>
          <Link color="inherit" to="/" className={classes.link}>
            {route}
          </Link>

          {props.navigation
            ? props.navigation.map((nav, i) => {
                return (
                  <Link
                    className={props.navigation.length - 1 === i ? classes.color : classes.link}
                    key={i}
                    to={`/${nav}`}
                    onClick={(e) => handleClick(nav, e)}
                  >
                    {nav}
                  </Link>
                );
              })
            : ''}
        </Breadcrumbs>
      </div>
      <div>{props.children}</div>
    </div>
  );
}
