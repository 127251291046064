import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import SearchBar from '../../SearchBar/SearchBar';
import Breadcrumb from '../../Breadcrumb/Breadcrumb';

import Loader from '../../Loader/Loader';

import placeholder from '../../../assets/images/placeholder1.png';
import b from '../../../assets/icons/b_logo.svg';

import { makeStyles } from '@material-ui/core/styles';
import './userList.css';

export default function UserList({ defaultSearchValue }) {
  const navigation = ['Users'];
  const history = useHistory();

  const {
    getUsers: { data, loading },
  } = useSelector(({ users }) => users);

  const useStyles = makeStyles({
    table: {
      width: '1370px',
      padding: '15px',
      fontFamily: 'Montserrat, serif',
      borderRadius: '10px 10px 0px 0px',
    },
    responsive: {
      width: '100%',
      overflowX: 'auto',
    },
    header: {
      textTransform: 'uppercase',
    },
    tableCell: {
      padding: '15px 20px',
      fontSize: '11px',
      fontWeigh: '500',
      backgroundColor: '#F4F3EE',
      color: '#4C443F',
    },
    userName: {
      color: '#62A8A4',
    },
    userPicture: {
      objectFit: 'cover',
      borderRadius: '50%',
      marginRight: '8px'
    },
    icon: {
      width: '10px',
      height: '13.5px',
    },
    tableData: {
      padding: '15px 20px',
      backgroundColor: '#FAF9F3',
      fontSize: '12px',
    },
    tableRow: {
      zIndex: 10,
      transition: '0.2s',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0px 2px 6px 4px #efedeb',
        position: 'relative',
      },
    },
  });

  const classes = useStyles();

  const handleUserDetailClick = data => () => {
    history.push(`/users/${data.user_id}`);
  };

  const onSearchSubmitHandle = query => {
    history.push(`/users?search=${query}`);
  }

  return (
    <>
      <Breadcrumb navigation={navigation}>
        <SearchBar defaultValue={defaultSearchValue} onSubmit={onSearchSubmitHandle} />
      </Breadcrumb>

      <TableContainer component={Paper} className={classes.responsive}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.tableCell} ${classes.header}`}>User</TableCell>
              <TableCell className={`${classes.tableCell} ${classes.header}`} align="left">
                Admin
              </TableCell>
              <TableCell className={`${classes.tableCell} ${classes.header}`} align="left">
                Questionnaire
              </TableCell>
              <TableCell className={`${classes.tableCell} ${classes.header}`} align="left">
                Plan
              </TableCell>
              <TableCell className={`${classes.tableCell} ${classes.header}`} align="left">
                Email
              </TableCell>
              <TableCell className={`${classes.tableCell} ${classes.header}`} align="left">
                Phone Number
              </TableCell>
              <TableCell className={`${classes.tableCell} ${classes.header}`} align="center">
                Movement
              </TableCell>
              <TableCell className={`${classes.tableCell} ${classes.header}`} align="center">
                Nutrition
              </TableCell>
              <TableCell className={`${classes.tableCell} ${classes.header}`} align="center">
                Self Care
              </TableCell>
            </TableRow>
          </TableHead>

          {loading && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={9} component="td" scope="row">
                  <div className="text-center">
                    <Loader className="fullPageLoader" />
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          <TableBody>
            {!loading && data.map((data) => (
              <TableRow key={data.id} onClick={handleUserDetailClick(data)} className={classes.tableRow}>
                <TableCell component="td" scope="row" className={`${classes.userName} ${classes.tableData}`}>
                  <img className={classes.userPicture} src={data.image || placeholder} alt="profile" width="40" height="40" />
                  <span className="ps-3">{data.first_name} {data.last_name}</span>
                </TableCell>
                <TableCell align="left" className={`${classes.tableCell} ${classes.tableData}`}>
                  {data.is_admin ? <img src={b} alt="blaque" /> : <p></p>}
                </TableCell>
                <TableCell align="left" className={`${classes.tableCell} ${classes.tableData}`}>
                  {data.question_detail.length ? data.question_detail.length : 'N/a'}
                </TableCell>
                <TableCell align="left" className={`${classes.tableCell} ${classes.tableData}`}>
                  {data.movement_plan ? data.movement_plan : 'N/a'}
                </TableCell>
                <TableCell align="left" className={`${classes.tableCell} ${classes.tableData}`}>
                  {data.email}
                </TableCell>
                <TableCell align="left" className={`${classes.tableCell} ${classes.tableData}`}>
                  {data.phone_number}
                </TableCell>
                <TableCell align="center" className={`${classes.tableCell} ${classes.tableData}`}>
                  {data.movement ? data.movement.toFixed(2) : 'N/a'}
                </TableCell>
                <TableCell align="center" className={`${classes.tableCell} ${classes.tableData}`}>
                  {data.nutrition ? data.nutrition.toFixed(2) : 'N/a'}
                </TableCell>
                <TableCell align="center" className={`${classes.tableCell} ${classes.tableData}`}>
                  {data.self_care ? data.self_care.toFixed(2) : 'N/a'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
