import { orderBy } from 'lodash';

export const GET_MOVEMENT_TYPES_REQUEST = 'GET_MOVEMENT_TYPES_REQUEST';
export const GET_MOVEMENT_TYPES_SUCCESS = 'GET_MOVEMENT_TYPES_SUCCESS';
export const GET_MOVEMENT_TYPES_ERROR = 'GET_MOVEMENT_TYPES_ERROR';

export const UPDATE_MOVEMENT_TYPE_REQUEST = 'UPDATE_MOVEMENT_TYPE_REQUEST';
export const UPDATE_MOVEMENT_TYPE_SUCCESS = 'UPDATE_MOVEMENT_TYPE_SUCCESS';
export const UPDATE_MOVEMENT_TYPE_ERROR = 'UPDATE_MOVEMENT_TYPE_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getMovementTypes: { ...block, data: {} }
};

let movementTypeId = null;

export const MovementTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MOVEMENT_TYPES_REQUEST:
      return {
        ...state,
        getMovementTypes: {
          ...state.getMovementTypes,
          loading: true
        },
      };
    case GET_MOVEMENT_TYPES_SUCCESS:
      return {
        ...state,
        getMovementTypes: {
          loading: false,
          success: true,
          data: {
            ...action.data,
            results: orderBy(action.data.results, 'id', 'asc') // by name
          }
        },
      };
    case GET_MOVEMENT_TYPES_ERROR:
      return {
        ...state,
        getMovementTypes: {
          ...state.getMovementTypes,
          loading: false,
          error: action.error,
        },
      };

    case UPDATE_MOVEMENT_TYPE_REQUEST:
      return {
        ...state,
        getMovementTypes: {
          ...state.getMovementTypes,
          loading: true
        },
      };

    case UPDATE_MOVEMENT_TYPE_SUCCESS:
      movementTypeId = state.getMovementTypes.data.results.findIndex(
        (data) => data.id === action.data.id,
      );

      if (movementTypeId !== -1)
        state.getMovementTypes.data.results[movementTypeId] = action.data;

      return {
        ...state,
        getMovementTypes: {
          ...state.getMovementTypes,
          loading: false,
          success: true
        }
      }

    case UPDATE_MOVEMENT_TYPE_ERROR:
      const { id, error } = action.data;

      const movementTypeIdError = state.getMovementTypes.data.results.findIndex(
        (data) => data.id === id,
      );

      if (movementTypeIdError !== -1)
        state.getMovementTypes.data.results[movementTypeIdError].errors = error.response.data;

      return {
        ...state,
        getMovementTypes: {
          ...state.getMovementTypes,
          loading: false,
          success: false
        }
      }
    default:
      return state;
  }
};
