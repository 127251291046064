import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from '@material-ui/core/Button';
import InputTitle from '../../UI/InputTitle';
const ConnectvideoLevelTwo = () => {
  return (
    <>
      {' '}
      <div className={`newVideoWrapper pt-3 pb-3`}>
        <div className={`pt-2 `}>
          <InputTitle className={`me-3`}>Link</InputTitle>
        </div>
        <div className={`w-100`}>
          <Form.Group className="mb-3">
            <Form.Control type="text" placeholder="Workout name 1" value={`Workout name 1`} onChange={console.log} />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Select aria-label="Default select example" className={`form-control-select`}>
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Form.Group>
          <Button className={`whiteBtn `}>Add video</Button>
        </div>
      </div>{' '}
    </>
  );
};
export default ConnectvideoLevelTwo;
