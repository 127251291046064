import React, { useState } from 'react';
import './searchBar.css';
import Button from '@material-ui/core/Button';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import filter from '../../assets/icons/filter-green.svg';
import search from '../../assets/icons/search.svg';

const theme = createTheme({
  palette: {
    primary: {
      main: '#62A8A4',
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#62A8A4',
    textTransform: 'capitalize',
    width: '120px',
    height: '35px',
  },
  spacing: {
    margin: '0 10px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: '#4C443F',
    width: '785px',
    border: '1px solid #E8E8E8',
    borderRadius: '5px',
    fontSize: '11px',
    padding: 5,
  },
  iconButton: {
    padding: 10,
  },
}));

export default function SearchBar({ defaultValue = '', onSubmit }) {
  const classes = useStyles();

  const [ text, setText ] = useState(defaultValue);

  const onChangeHandle = event => {
    setText(event.target.value);
  }

  const onSubmitHandle = event => {
    event.preventDefault();
    onSubmit(text);
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        <form className="d-inline-block" onSubmit={onSubmitHandle}>
          <InputBase className={classes.input} placeholder="Search user" value={text} onChange={onChangeHandle} inputProps={{ 'aria-label': 'search' }} />
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <img src={search} alt="search" />
          </IconButton>
        </form>

        {/* <Button variant="outlined" className={classes.button}>
          <img src={filter} alt="filter" />
          <p className="searchBar-content">Filters</p>
          <Badge badgeContent={1} className={classes.spacing} color="primary" />
        </Button> */}
      </div>
    </ThemeProvider>
  );
}
