import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MovementTypeBox from './MovementTypeBox';
import Loader from '../../Loader/Loader';
import DefaultLightBox from '../../UI/DefaultLightBox';
import QuestionsPicker from '../../QuestionsDropdown';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';

import CustomToggle from '../../QuizAccordionUi/CustomToggleQuiz';

const PlansLeftContent = ({ activeScreen, setActiveScreen, typesData, loading }) => {
  return (
    <DefaultLightBox className="relative">
      <Row className={`p-3 relative`}>
        <Col>
          <p className="m-0">Movement Style</p>
        </Col>
        {/* <Col className={`text-end`}>
          <DropdownButton title="" drop={`start`} className={`menuDots right20`}>
            <Dropdown.Item href="#">Action</Dropdown.Item>
          </DropdownButton>
        </Col> */}
      </Row>

      <div className={`plansMain`}>
        <Accordion defaultActiveKey="0" activeKey={activeScreen}>
          {typesData.map(type => (
            <Card className={`border-0`} key={type.id}>
              <Card.Header className={`p-0 planLevelOne`}>
                <div>
                  <CustomToggle setActiveKey={setActiveScreen} eventKey={type.id} activeKey={activeScreen}>
                    &nbsp;{type.title}
                  </CustomToggle>
                </div>

                <div className="d-flex align-item-center">
                  <QuestionsPicker id={type.id} userType={{}} />
                </div>
              </Card.Header>

              <Accordion.Collapse eventKey={type.id}>
                <>
                  <MovementTypeBox
                    type={type}
                  />
                </>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>

      {loading && <Loader className="flexibleLoader" />}
    </DefaultLightBox>
  );
};

export default PlansLeftContent;
