import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import loginCss from '../../pages/Login/loginCss';

const SignUp = () => {
  const classes = loginCss();
  const [authData, setAuthData] = React.useState({
    fname: '',
    lname: '',
    username: '',
    password: '',
  });

  const [authError, setAuthError] = React.useState({
    username: '',
    password: '',
  });

  const handleChange = ({ target: { name, value } }) => {
    setAuthData({ ...authData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div className={`row`}>
          <div className={`col-sm-6 mb-3`}>
            <TextField
              id="fname"
              label="First Name"
              name={'fname'}
              type={'text'}
              value={authData.name}
              error={false}
              helperText={``}
              onChange={handleChange}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
          </div>

          <div className={`col-sm-6 mb-3`}>
            <TextField
              id="lname"
              label="Last Name"
              name={'lname'}
              type={'text'}
              value={authData.username}
              error={false}
              helperText={``}
              onChange={handleChange}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
          </div>
          <div className={`col-12 mb-3`}>
            <TextField
              id="username"
              label="Email"
              name={'username'}
              type={'email'}
              value={authData.username}
              error={false}
              helperText={``}
              onChange={handleChange}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
          </div>
          <div className={`col-12 mb-3`}>
            <TextField
              id="password"
              label="Enter your password"
              name={'password'}
              type={'password'}
              value={authData.password}
              error={false}
              helperText={``}
              onChange={handleChange}
              classes={{
                root: classes.textFieldRoot,
              }}
            />
          </div>
          <div className={`col-12 mb-3 pt-2`}>
            <Button variant="contained" type={`submit`}>
              SIGN UP
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default SignUp;
