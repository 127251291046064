// import { orderBy } from 'lodash';

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';

export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST';
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS';
export const EDIT_CATEGORY_ERROR = 'EDIT_CATEGORY_ERROR';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const GET_EXERCISES_REQUEST = 'GET_EXERCISES_REQUEST';
export const GET_EXERCISES_SUCCESS = 'GET_EXERCISES_SUCCESS';
export const GET_EXERCISES_ERROR = 'GET_EXERCISES_ERROR';

export const ADD_EXERCISE_REQUEST = 'ADD_EXERCISE_REQUEST';
export const ADD_EXERCISE_SUCCESS = 'ADD_EXERCISE_SUCCESS';
export const ADD_EXERCISE_ERROR = 'ADD_EXERCISE_ERROR';

export const EDIT_EXERCISE_REQUEST = 'EDIT_EXERCISE_REQUEST';
export const EDIT_EXERCISE_SUCCESS = 'EDIT_EXERCISE_SUCCESS';
export const EDIT_EXERCISE_ERROR = 'EDIT_EXERCISE_ERROR';

export const DELETE_EXERCISE_REQUEST = 'DELETE_EXERCISE_REQUEST';
export const DELETE_EXERCISE_SUCCESS = 'DELETE_EXERCISE_SUCCESS';
export const DELETE_EXERCISE_ERROR = 'DELETE_EXERCISE_ERROR';

const block = {
  loading: false,
  error: '',
  success: false,
};

const initialState = {
  getExercises: { ...block, data: [] }
};

export const ExercisesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORY_REQUEST:
      return {
        ...state,
        getExercises: { ...state.getExercises, loading: true }
      };
    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          success: true,
          data: [...state.getExercises.data, action.data]
        },
      };
    case ADD_CATEGORY_ERROR:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          error: action.error,
        },
      };

    case EDIT_CATEGORY_REQUEST:
      return {
        ...state,
        getExercises: { ...state.getExercises }
      };
    case EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          success: true,
          data: [...state.getExercises.data.map(category => category.id === action.data.id ? {...category, name: action.data.data.name} : category)]
        },
      };
    case EDIT_CATEGORY_ERROR:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          error: action.error,
        },
      };

    case DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        getExercises: { ...state.getExercises, loading: true }
      };
    case DELETE_CATEGORY_SUCCESS:
      console.log(state.getExercises.data, action.data)

      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          success: true,
          data: [...state.getExercises.data.filter(c => c.id !== action.data)]
        },
      };
    case DELETE_CATEGORY_ERROR:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          error: action.error,
        },
      };

    case GET_EXERCISES_REQUEST:
      return {
        ...state,
        getExercises: { ...state.getExercises, loading: true },
      };
    case GET_EXERCISES_SUCCESS:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          success: true,
          data: action.data,
        },
      };
    case GET_EXERCISES_ERROR:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          error: action.error,
        },
      };

    case ADD_EXERCISE_REQUEST:
      return {
        ...state,
        getExercises: { ...state.getExercises, loading: true }
      };
    case ADD_EXERCISE_SUCCESS:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          success: true,
          data: state.getExercises.data.map(category => category.id === action.data.category ? { ...category, exercises: [ ...category.exercises, action.data ] } : category)
        },
      };
    case ADD_EXERCISE_ERROR:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          error: action.error,
        },
      };

    case EDIT_EXERCISE_REQUEST:
      return {
        ...state,
        getExercises: { ...state.getExercises, loading: action.payload.data instanceof FormData }
      };
    case EDIT_EXERCISE_SUCCESS:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          success: true,
          data: state.getExercises.data.map(category => category.id === action.data.category ? { ...category, exercises: category.exercises.map(exercise => exercise.id === action.data.id ? action.data : exercise) } : category)
        },
      };
    case EDIT_EXERCISE_ERROR:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          error: action.error,
        },
      };

    case DELETE_EXERCISE_REQUEST:
      return {
        ...state,
        getExercises: { ...state.getExercises, loading: true }
      };
    case DELETE_EXERCISE_SUCCESS:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          success: true,
          data: state.getExercises.data.map(category => category.id === action.data.category ? { ...category, exercises: category.exercises.filter(exercise => exercise.id !== action.data) } : category)
        },
      };
    case DELETE_EXERCISE_ERROR:
      return {
        ...state,
        getExercises: {
          ...state.getExercises,
          loading: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
};
