import { makeStyles } from '@material-ui/core';

const loginCss = makeStyles((theme) => ({
  main: {
    padding: 25,
    display: 'flex',
    alignItems: 'center',
    paddingTop: '8%',
    flexDirection: 'column',
  },
  authBox: {
    margin: '0 auto',
    background: theme.palette.background.topLevel,
    padding: 20,
    borderRadius: 5,
    maxWidth: '500px',
    width: '100%',
  },
  loginTitel: {
    color: theme.palette.text.primary,
    fontSize: '1rem',
  },
  textFieldRoot: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: theme.palette.text.primary,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: '#f44336',
    },
  },
  activeModelTitle: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    fontSize: '1.5rem !important',
  },
}));

export default loginCss;
