import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PlansUsersLevel from './PlansUsersLevel';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

// import NutritionLevel from './NutritionLevel';
import './plansUsers.scss';

import {
  addMovementWorkoutLevel,
  addNutritionLevel,
  deleteMovementWorkoutLevel,
  deleteNutritionLevel,
  getNutritionPlan,
} from '../../../../../redux/actions/PlansAction';

const PlansUsers = ({ userTypeId }) => {
  const dispatch = useDispatch();
  const {
    nutritionLevel: { data: getNutritionLevel },
  } = useSelector(({ plan }) => plan);
  const [nutritionLevel, setNutritionLevel] = useState([]);
  const [levelActive, setLevelActive] = useState(1);

  const handleAddLevel = key => {
    setLevelActive(+key);
    // handle add level
    if (key === 'Add level') {
      const currentLevel = nutritionLevel.results
        ? Math.max.apply(
          Math,
          nutritionLevel.results.map(function (o) {
            return o.order;
          }),
        ) + 1
        : 1;

      dispatch(
        addNutritionLevel({
          user_type: userTypeId,
          order: currentLevel === -Infinity ? 1 : currentLevel,
        }),
      );
      return;
    }

    // handle remove level
    if (key === 'Delete level') {
      const level = nutritionLevel.results.find(i => i.id === levelActive);

      dispatch(deleteNutritionLevel(level.id));
      setLevelActive(null);

      return;
    }
  };

  useEffect(() => {
    if (getNutritionLevel) {
      setNutritionLevel(getNutritionLevel);
    }
  }, [getNutritionLevel]);

  return (
    <>
      <div className="plansUsers">
        <Tabs
          activeKey={levelActive}
          id="plansUsers"
          onSelect={handleAddLevel}
        >
          {nutritionLevel?.results &&
            nutritionLevel?.results?.filter(level => level.user_type === userTypeId).map((data, index) => {
              return (
                <Tab
                  eventKey={data.id}
                  title={`Level ${index+1}`}
                  key={index}
                >
                  <PlansUsersLevel />
                </Tab>
              );
            })}
          <Tab
            eventKey="Add level"
            title="Add level"
            tabClassName="tabAddBtn"
          />
          {!!nutritionLevel?.results?.length && !!levelActive && (
            <Tab
              eventKey="Delete level"
              title="Delete level"
              tabClassName="tabDeleteBtn"
            />
          )}
        </Tabs>
      </div>{' '}
    </>
  );
};

export default PlansUsers;
