import React from 'react';
import { Link } from 'react-router-dom';

import Carousel from 'react-material-ui-carousel';
import MessagesCarousel from './MessagesCarousel';

import rectangles from '../../../assets/images/short-rectangle.png';
import CarouselIcon from '../../../assets/icons/CarouselIcon.svg';
import CarouselActive from '../../../assets/icons/CarouselActive.svg';
import placeholder from '../../../assets/images/placeholder1.png';

import './recentMessages.css';

export default function RecentMessages() {
  const items = [
    {
      id: '0001',
      name: 'Alexa Mills',
      description: 'Probably the most random thing you have ever seen!',
      img: placeholder,
      tagline: 'Exercise name',
    },
    {
      id: '0002',
      name: 'Random Name #2',
      description: 'Lorem ipsum dolor sit sectetur lorem distrep ...',
      img: placeholder,
      tagline: 'Exercise',
    },
    {
      id: '0003',
      name: 'Alexa Mills',
      description: 'Probably the most random thing you have ever seen!',
      img: placeholder,
      tagline: 'Exercise name',
    },
    {
      id: '0004',
      name: 'Random Name #2',
      description: 'Lorem ipsum dolor sit sectetur lorem distrep ...',
      img: placeholder,
      tagline: 'Exercise',
    },
  ];

  return (
    <div className="messages-container">
      <div className="messages-subcontainer">
        <div className="message-title">
          <span className="recent-messages">Recent Messages</span>
          <span className="menu-messages" />
          <Link className="all-messages" to="/messages">
            View all Messages
          </Link>
        </div>

        <Carousel
          IndicatorIcon={' '}
          indicatorIconButtonProps={{
            style: {
              padding: '5px',
              margin: '3px', // 1
              color: 'transparent',
              backgroundImage: `url('${CarouselIcon}')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '8px',
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundImage: `url('${CarouselActive}')`,
              backgroundSize: '8px',
            },
          }}
          className={'overFlowView'}
          indicatorContainerProps={`demo`}
        >
          {items.map(item => (
            <MessagesCarousel key={item.id} item={item} />
          ))}
        </Carousel>

        <div className="rectangles d-flex align-items-center">
          <img src={rectangles} alt="rectangles" />
          <span>10</span>
        </div>
      </div>
    </div>
  );
}
