import React, { useState } from 'react';
const DaysPickerComponents = ({ handleClose, weekDays, setWeekDays }) => {
  const [allDays, setAllDays] = useState(weekDays);
  const handleClick = (currentDay) => () => {
    const index = allDays.findIndex((day) => day.title === currentDay.title);
    if (index > -1) {
      const updateWeekDays = [
        ...allDays.slice(0, index),
        {
          ...currentDay,
          isSelect: !currentDay.isSelect,
        },
        ...allDays.slice(index + 1),
      ];
      setAllDays(updateWeekDays);
    }
  };
  const handleSave = () => {
    setWeekDays(allDays);

    handleClose();
  };
  return (
    <div className="datePickerWrapper">
      <div className="text-center text-uppercase">Select days</div>
      <div className="boxWrapper text-uppercase">
        {allDays.map((day) => (
          <div key={day.title}>
            <span className={day.isSelect ? 'activeDate' : ''} onClick={handleClick(day)}>
              {day.title}
            </span>
          </div>
        ))}
      </div>
      <div className="footerDate">
        <span>All</span>
        <p>
          <span onClick={handleClose}>Cancel</span>
          <span onClick={handleSave}>Done</span>
        </p>
      </div>
    </div>
  );
};
export default DaysPickerComponents;
