import React, { useState, useEffect, useRef } from 'react';
import './weightLoss.scss';
import Form from 'react-bootstrap/Form';
import GymImg from '../../../../assets/images/video.png';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    addMorningMoment,
    deleteMorningMoment,
    getMorningMoment
} from '../../../../redux/actions/PlansAction';
import moment from 'moment';
import Loader from '../../../Loader/Loader';
import { Link } from 'react-router-dom';

const MorningMoments = ({ userTypeId }) => {
    const dispatch = useDispatch();
    const {
        morningMoment: { data: morningMomentData = [], loading },
        addMorningMoment: { success, loading: addMomentLoading }
    } = useSelector(({ plan }) => plan);

    const formRef = useRef(null);

    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [video, setVideo] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [data, setData] = useState([]);

    const handleAddMoment = e => {
        e.preventDefault();

        const formData = new FormData();

        formData.append('user_type', userTypeId);
        formData.append('name', name);
        formData.append('date', date);
        formData.append('video', video);
        formData.append('description', description);

        dispatch(addMorningMoment(formData));
    };

    const onDateChange = event => {
        setDate(event.target.value);
    };

    const onVideoChange = event => {
        // console.log(event.target.files[0])
        setVideo(event.target.files[0]);
    };

    const onNameChange = event => {
        setName(event.target.value);
    };

    const onDescriptionChange = event => {
        setDescription(event.target.value);
    };

    useEffect(() => {
        if (morningMomentData) {
            setData(morningMomentData.results);
        }
    }, [morningMomentData]);

    useEffect(() => {
        if (success) {
            setDate('');
            setVideo('');
            setName('');
            setDescription('');

            formRef.current && formRef.current.reset();
        }
    }, [success]);

    const handleDeleteMoment = id => () => {
        dispatch(deleteMorningMoment({ id }));
    };

    return (
        <>
            {loading ? (
                <div className="text-center borderBottom">
                    <Loader className="smallLoader" />
                </div>
            ) : (
                <div className="morningMomentsWrapper">
                    <Form ref={formRef} onSubmit={handleAddMoment}>
                        <div className="morningMomentFormWrapper">
                            <Row>
                                <Col sm="4">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={name}
                                            onChange={onNameChange}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Day*</Form.Label>
                                        <Form.Control
                                            required
                                            type="date"
                                            placeholder="Today"
                                            value={date}
                                            onChange={onDateChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm="8">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Description*</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            value={description}
                                            onChange={onDescriptionChange}
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Video*</Form.Label>
                                        <Form.Control
                                            type="file"
                                            required
                                            onChange={onVideoChange}
                                        />
                                    </Form.Group>

                                    <div className="text-end">
                                        <button className="actionMoment" type="submit" disabled={addMomentLoading}>
                                            <span className="secondaryText">
                                                {addMomentLoading ? 'Uploading...' : 'Add moment'}
                                            </span>
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Form>

                    <div className="momentTableWrapper">
                        <table className="w-100">
                            <tr className="tableTitle">
                                <td>Video</td>
                                <td>Name</td>
                                <td>Date</td>
                                <td>Description</td>
                                <td />
                            </tr>
                            {data?.map((data, index) => (
                                <tr className="tableData" key={index}>
                                    <td>
                                        <a href={data.video} target="_blank">
                                            <img src={GymImg} alt="image" />
                                        </a>
                                    </td>
                                    <td>
                                        <span>{data?.name}</span>
                                    </td>
                                    <td>
                                        <span>{data?.date}</span>
                                    </td>
                                    <td>
                                        <span>{data?.description}</span>
                                    </td>
                                    <td>
                                        <DropdownButton
                                            title=""
                                            drop={`start`}
                                            className={`menuDots`}
                                        >
                                            <Dropdown.Item
                                                onClick={handleDeleteMoment(
                                                    data.id
                                                )}
                                            >
                                                Delete
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};
export default MorningMoments;
