import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
const ActivityProgress = ({ data }) => {
  const { title = 'Title', value = 50 } = data;
  return (
    <div className="activityProgressWrapper borderBottom">
      <div className="activityTitle">
        <p className="pe-2">{title}</p>
      </div>
      <div className="d-flex align-item-center w-100">
        <ProgressBar now={value} />
        <div className="ps-3">{value}%</div>
      </div>
    </div>
  );
};
export default ActivityProgress;
