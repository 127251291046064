import { createTheme } from '@material-ui/core/styles';
const theme = createTheme({
  palette: {
    primary: {
      light: '#CEBDA9',
      main: '#4C443F',
    },
    secondary: {
      main: '#C7922C',
      light: '#EFEBE6',
    },
    success: {
      main: '#62A8A4',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      main: '#e5e5e5',
      topLevel: '#F4F3EE',
    },
    link: {
      main: '#4C443F',
    },
    text: {
      primary: '#4C443F',
      secondary: '#62a8a4',
      secondaryLight: '#CEBDA9',
    },
  },
});
export default theme;
