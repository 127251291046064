import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputWithSearch from '../../../InputWithSearch/InputWithSearch';
import DefaultLightBox from '../../../UI/DefaultLightBox';
import './MorningMomentsLibrary.scss';
import GymImg from '../../../../assets/images/video.png';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

const MorningMomentsLibrary = () => {
  const data = [
    { id: 1, title: 'Video name 1' },
    { id: 2, title: 'Video name 2' },
  ];
  return (
    <DefaultLightBox className={'momentsLibraryWrapper'}>
      <Row className={`p-3 relative align-item-center`}>
        <Col>
          <p className="m-0 text-capitalize">morning moments library</p>
        </Col>
        <Col className={`secondaryText`}>
          <span>Add new habit</span>
        </Col>
        <Col md={12} className="pt-3">
          <InputWithSearch placeholder={'Search library'} />
        </Col>
      </Row>
      {data &&
        data.map((data, index) => (
          <div className="morningMomentsLibrary" key={index}>
            <div className="d-flex align-item-center">
              <p className="m-0">{data.title}</p>
            </div>
            <div>
              <img src={GymImg} />
              <DropdownButton title="" drop={`start`} className={`menuDots`}>
                <Dropdown.Item href="#">Action</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        ))}
    </DefaultLightBox>
  );
};
export default MorningMomentsLibrary;
