import React, { useState } from 'react';
import './addNewCategory.scss';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
const AddNewCategory = ({ handleAddToggle }) => {
  const dispatch = useDispatch();
  const [inputText, setInputText] = useState('');
  const handleChange = ({ target: { value } }) => {
    setInputText(value);
  };

  const handleSubmit = () => {
    // dispatch call hear
    handleAddToggle();
  };
  return (
    <>
      <div className="addNewCategoryWrapper">
        <div className="row">
          <InputBase placeholder="new category" className="inputBox" value={inputText} onChange={handleChange} />
          <div className="d-flex flex-row-reverse">
            <Button className="whiteBtn m-0 mt-2" onClick={handleSubmit}>
              + Add new category
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddNewCategory;
