import * as React from 'react';

function Clock(props) {
  return (
    <svg width={14} height={17} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.535 6.036A6.678 6.678 0 0114 10.179a6.676 6.676 0 01-1.44 4.15 7.014 7.014 0 01-3.746 2.439 7.192 7.192 0 01-4.499-.288A6.94 6.94 0 01.93 13.586a6.638 6.638 0 01-.867-4.299 6.73 6.73 0 012.012-3.92A7.083 7.083 0 016.13 3.45v-1.75h1.75v1.75a7.024 7.024 0 013.412 1.377l1.278-1.24 1.242 1.207-1.277 1.232v.009zm-5.53 9.266a5.33 5.33 0 003.712-1.494 5.028 5.028 0 001.538-3.607c0-1.353-.553-2.65-1.538-3.607a5.329 5.329 0 00-3.712-1.493 5.329 5.329 0 00-3.713 1.493 5.028 5.028 0 00-1.537 3.607c0 1.353.553 2.65 1.537 3.607a5.33 5.33 0 003.713 1.494zM4.38 0h5.25v1.7H4.38V0zm4.48 7.192l1.242 1.207-3.097 2.992-1.234-1.19 3.089-3.01z"
        fill="#CEBDA9"
      />
    </svg>
  );
}

export default Clock;
