import React, { useState } from 'react';
import DownIcon from '../../../assets/icons/DownIcon';
const WeekPickerComponents = ({
  weekNumber,
  handleClose,
  pageIndex,
  setPageIndex,
  selectedWeekNumber,
  setSelectedWeekNumber,
}) => {
  const [week, setWeek] = useState(selectedWeekNumber);
  const handleUp = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 10);
  };
  const handleDown = () => {
    if (pageIndex < 50) setPageIndex(pageIndex + 10);
  };
  const handleClick = (currentDay) => () => {
    const index = week.findIndex((day) => day === currentDay);
    if (index > -1) {
      week.splice(index, 1);
      setWeek([...week]);
    } else {
      setWeek([...week, currentDay]);
    }
  };
  const handleSave = () => {
    setSelectedWeekNumber(week);
    handleClose();
  };
  return (
    <div className="weekPickerWrapper">
      <div className="text-center text-uppercase">Select days</div>
      <div className="boxWrapper text-uppercase">
        {weekNumber.map((day) => (
          <div key={day.title}>
            <span
              className={week.includes(day.title + pageIndex) ? 'activeDate' : ''}
              onClick={handleClick(day.title + pageIndex)}
            >
              {day.title + pageIndex}
            </span>
          </div>
        ))}
        <div>
          <span onClick={handleUp} className="upIcon">
            <DownIcon />
          </span>
        </div>
        <div>
          <span onClick={handleDown}>
            <DownIcon />
          </span>
        </div>
      </div>
      <div className="footerDate">
        <span>All</span>
        <p>
          <span onClick={handleClose}>Cancel</span>
          <span onClick={handleSave}>Done</span>
        </p>
      </div>
    </div>
  );
};
export default WeekPickerComponents;
