import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

import Cookies from 'js-cookie';

import logo from '../../assets/images/bm_logo_bone1.png';
import './navBar.css';

const Navigation = () => {
  const logout = () => {
    Cookies.remove('token');
    return (window.location.href = '/login');
  };

  return (
    <Navbar collapseOnSelect expand="xl">
      <Container className={`p-0`}>
        <Navbar.Brand>
          <Link to="/dashboard">
            <img src={logo} alt="Logo" />
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavLink to="/" activeClassName="active-nav" exact>
              Dashboard
            </NavLink>
            <NavLink to="/plans" activeClassName="active-nav">
              Plans
            </NavLink>
            <NavLink to="/movement-style" activeClassName="active-nav">
              Movement Style
            </NavLink>
            <NavLink to="/exercises" activeClassName="active-nav">
              Exercises
            </NavLink>
            <NavLink to="/quizz" activeClassName="active-nav">
              Quizz setup
            </NavLink>
            <NavLink to="/users" activeClassName="active-nav">
              Users
            </NavLink>
            <NavLink to="/messages" activeClassName="active-nav">
              Messages
            </NavLink>
            <NavLink
              to="/communityChat"
              activeClassName="active-nav"
            >
              Community chat
            </NavLink>
            <NavLink
              to="/login"
              onClick={logout}
              activeClassName="active-nav"
            >
              Logout
            </NavLink>
          </Nav>

          {/*<Nav>*/}
          {/*    <div className="portrait">*/}
          {/*        <div className="portrait-name">*/}
          {/*            <p className="margin">T'Nisha</p>*/}
          {/*            <p className="margin">Glenn</p>*/}
          {/*        </div>*/}
          {/*        <img className="portrait-pic" src={placeholder} alt="placeholder"/>*/}
          {/*    </div>*/}
          {/*</Nav>*/}

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
