import React from 'react';
import Tab from 'react-bootstrap/Tab';
import PlansUsers from '../Movement/PlansUsers/PlansUsers';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import './sleep.scss';
import NutritionPlans from './NutritionPlans';
const Sleep = () => {
  return (
    <Tabs defaultActiveKey="nutritionPlans" id="sleepPlans" className="titleTab">
      <Tab eventKey="nutritionPlans" title="Nutrition plans">
        <NutritionPlans />
      </Tab>
      <Tab eventKey="users" title="users">
        <PlansUsers />
      </Tab>
      <Tab eventKey="plan" title="Plan description">
        <div className="descriptionBox">
          <p>My black movement description for this plan</p>
          <Form.Control as="textarea" style={{ height: '100px' }} />
        </div>
      </Tab>
    </Tabs>
  );
};
export default Sleep;
