import React, { useState } from 'react';

import Form from 'react-bootstrap/Form';

import './styles.css';

const VideoLibraryLevelThree = ({ exercise, trainers, onEdit }) => {
  const [description, setDescription] = useState(exercise.description);

  const onDescriptionChangeHandle = event => {
    setDescription(event.target.value);
  }

  const onDescriptionBlurHandle = () => {
    if (exercise.description !== description)
      onEdit(exercise.id, { description });
  }

  const onDescriptionKeyDownHandle = event => {
    if (event.keyCode === 13 && exercise.description !== description)
      onEdit(exercise.id, { description });
  }

  const onTrainerChangeHandle = event => {
    const { value } = event.target;

    if (value !== '0' && value != exercise.trainer)
      onEdit(exercise.id, { trainer: value });
  }

  const onFileChangeHandle = event => {
    var formData = new FormData();

    formData.append('image', event.target.files[0]);

    onEdit(exercise.id, formData)
  }

  const onVideoChangeHandle = event => {
    var formData = new FormData();

    formData.append('video', event.target.files[0]);

    onEdit(exercise.id, formData)
  }

  return (
    <>
      <div className={`videoWrapper pt-3`}>
        <div className={`video`}>
          {exercise.image && <img className="mb-3" src={exercise.image} width="220" />}
          <Form.Group>
            <Form.Label>Image</Form.Label>
            <Form.Control type="file" onChange={onFileChangeHandle} />
          </Form.Group>
        </div>

        <div className={`videoInfo`}>
          <Form.Group className="mb-3">
            <Form.Label>About the video</Form.Label>
            <Form.Control
              type="text"
              placeholder="Workout name"
              value={description}
              onChange={onDescriptionChangeHandle}
              onBlur={onDescriptionBlurHandle}
              onKeyDown={onDescriptionKeyDownHandle}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Trainer</Form.Label>
            <Form.Group className="mb-2">
              <Form.Select aria-label="Default select example" className={`form-control-select`} value={exercise.trainer} onChange={onTrainerChangeHandle}>
                <option value="0">Select a trainer</option>
                {trainers.map(trainer => <option key={trainer.id} value={trainer.id}>{trainer.name}</option>)}
              </Form.Select>
            </Form.Group>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Video</Form.Label>
            <Form.Control
              type="file"
              onChange={event => onVideoChangeHandle(event, exercise.id)}
            />
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default VideoLibraryLevelThree;
