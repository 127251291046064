import React, { useState } from 'react';
import './navInformation.css';
import Stats from '../Stats/Stats';
import Messages from './RecentMessages/RecentMessages';
import NewUsersTable from '../NewUsersTable/NewUsersTable';
import PlansTable from '../PlansTable/PlansTable';

export default function NavInformation() {
  return (
    <div className="row align-items-center pt-2">
      <div className={`col-lg-8 py-2`}>
        <Stats />
      </div>
      <div className={`col-lg-4 py-2`}>
        <Messages />
      </div>
    </div>
  );
}
