import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const ChartUserActivity = ({ quizz, goals, movements }) => {
  const data = [
    { name: 'Completed Quizz', value: quizz },
    { name: 'Set Goals', value: parseInt(goals) },
    { name: 'Movement', value: parseInt(movements) },
  ];

  const COLORS = ['#E7A053', '#DBB993', '#CEBDA9'];

  return (
    <div>
      <PieChart width={89} height={89}>
        <Pie
          data={data}
          cx={40}
          cy={40}
          innerRadius={30}
          outerRadius={35}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <text x={45} y={40} textAnchor="middle" dominantBaseline="middle" fill={`#CEBDA9`}>
          User
        </text>

        <text x={45} y={50} textAnchor="middle" dominantBaseline="middle" fill={`#CEBDA9`}>
          Activity
        </text>
      </PieChart>
    </div>
  );
};

export default ChartUserActivity;
