import React, { useState } from 'react';
import './SelfCare.scss';
import { Accordion } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CustomToggleExercises from '../../../Exercises/ExercisesAccordionUi/CustomToggleExercises';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DefaultLightBox from '../../../UI/DefaultLightBox';
import ArrowIcon from '../../../../assets/icons/arrow';
const SelfCare = () => {
  const [activeKey, setActiveKey] = useState('1');
  const data = [
    { id: 2, title: 'Sleep', user: 120 },
    { id: 3, title: 'Mindfulness', user: 220 },
    { id: 1, title: 'Mindfulness', user: 80 },
  ];
  return (
    <>
      <DefaultLightBox>
        <Row className={`p-3 relative`}>
          <Col>
            <p className="m-0">Self care</p>
          </Col>
          {/* <Col className={`text-end`}>
            <DropdownButton title="" drop={`start`} className={`menuDots right20`}>
              <Dropdown.Item href="#">Action</Dropdown.Item>
            </DropdownButton>
          </Col> */}
        </Row>
        <div className="plansMain">
          <Accordion defaultActiveKey="0" activeKey={activeKey}>
            {data.map((data, index) => (
              <Card className={`border-0`} key={index}>
                <Card.Header className={`planLevelOne`}>
                  <CustomToggleExercises setActiveKey={setActiveKey} eventKey={index} activeKey={activeKey}>
                    &nbsp; {data.title}
                  </CustomToggleExercises>
                  <ArrowIcon />
                </Card.Header>

                <Accordion.Collapse eventKey={index}>
                  <>
                    <div className="p-3">{data.title}</div>
                  </>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </div>
      </DefaultLightBox>
    </>
  );
};
export default SelfCare;
