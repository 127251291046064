import React from 'react';
import { Link } from 'react-router-dom';

// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';

import placeholder from '../../../assets/images/placeholder1.png';
import './userChatList.scss';

const UserChatList = ({ data }) => {
  return (
    <Link className="listWrapper" to={`/users/${data.user_id}`}>
      <div className="userImgWrapper">
        <img src={data.image || placeholder} width={50} height={50} alt={`${data.first_name} ${data.last_name}`} />
      </div>
      <div className="userMessageWrapper">
        <p className="nameUser">{data.first_name} {data.last_name}</p>
        <p className="messageUser">{data.user_type}</p>
      </div>

      {/* <div className="userActionIcon">
        <DropdownButton
          title=""
          drop={`start`}
          className={`menuDots`}
        >
          <Dropdown.Item>Delete</Dropdown.Item>
        </DropdownButton>
      </div> */}
    </Link>
  );
};

export default UserChatList;
